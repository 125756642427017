import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InfoIcon from '@mui/icons-material/Info'
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Skeleton, Button, Stack, AccordionSummary, Accordion, AccordionDetails,
} from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { type FunctionComponent, useState } from 'react'

import { useAppDispatch, useAppSelector } from 'app/store'

import {selectBrokers, restartConsumer, stopConsumer, loadBrokers, moveConsumerMessageToError} from '../../../app/store/administration/brokerSlice'
import { TextDialog } from '../../sync/TextDialog/ui/TextDialog'

const BrokersList: FunctionComponent = () => {

  const dispatch = useAppDispatch()
  const brokersState = useAppSelector(selectBrokers)
  const {
    status,
    brokers,
  } = brokersState
  const [text, setText] = useState<string | undefined>(undefined)

  if (status === 'loading') {
    return (
      <List>
        {Array.from(Array(5).keys()).map((i) => {
          return (
            <Box
              key={i}
              sx={{ width: '100%' }}
            >
              <Skeleton/>
            </Box>
          )
        })}
      </List>
    )
  }

  return (
    <>
      <List>
        {brokers && brokers.map(broker => {
          return (
            <Accordion
              key={broker.id}
              defaultExpanded
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="broker-content"
                id="broker-header"
              >
                <Stack
                  spacing={2}
                  direction={'row'}
                  alignItems={'center'}
                  justifyContent={'center'}
                >
                  <Tooltip
                    arrow
                    placement="bottom"
                    title={broker.id}
                  >
                    <Typography>
                      {broker.name}
                    </Typography>
                  </Tooltip>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                {broker.consumers && broker.consumers.map((consumer) => {
                  return (
                    <ListItem
                      key={`brokers-list-item-${consumer.id}`}
                      sx={{
                        flexWrap: 'wrap',
                        alignContent: 'center',
                      }}
                    >
                      <ListItemText
                        primary={consumer.name}
                        secondary={
                          <Stack
                            spacing={1}
                            direction={'row'}
                          >
                            {consumer.statusInfo.status}
                          </Stack>
                        }
                        sx={{ wordBreak: 'break-аll' }}
                      />
                      <Stack
                        direction="row"
                        spacing={2}
                        alignItems={'center'}
                      >
                        <Tooltip
                          arrow
                          placement="bottom"
                          title={
                            <Stack direction={'column'}>
                              <Typography>{`Is connected: ${consumer.isConnected}`}</Typography>
                              <Typography>{`Is connecting: ${consumer.isConnecting}`}</Typography>
                              <Typography>{`Is consuming: ${consumer.isConsuming}`}</Typography>
                              <Typography>{`Latest consumed message timestamp: ${consumer.statusInfo.latestConsumedMessageTimestamp}`}</Typography>
                              <Typography>{`Latest consumed offset: ${consumer.statusInfo.latestConsumedMessageIdentifier.lastOffset}`}</Typography>
                            </Stack>
                          }
                        >
                          <InfoIcon/>
                        </Tooltip>
                        <Button
                          disabled={consumer.fetchStatus !== 'idle' || consumer.isConnected}
                          onClick={async (e) => {
                            e.stopPropagation()
                            dispatch(restartConsumer({
                              id: broker.id,
                              name: consumer.name,
                            }))
                            dispatch(loadBrokers()).then(() => {
                              dispatch(loadBrokers())
                            })
                          }}
                        >Start</Button>
                        <Button
                          disabled={consumer.fetchStatus !== 'idle' || !consumer.isConnected}
                          onClick={async (e) => {
                            e.stopPropagation()
                            dispatch(stopConsumer({
                              id: broker.id,
                              name: consumer.name,
                            })).then(() => {
                              dispatch(loadBrokers())
                            })

                          }}
                        >Stop</Button>
                        <Tooltip
                          arrow
                          placement="bottom"
                          title={
                            <Stack direction={'column'}>
                              <Typography>{`Move next message in ${consumer.name}  to errors`}</Typography>
                            </Stack>
                          }
                        >
                          <Button
                            disabled={consumer.fetchStatus !== 'idle' || consumer.isConnected}
                            onClick={async (e) => {
                              e.stopPropagation()
                              dispatch(moveConsumerMessageToError({
                                id: broker.id,
                                name: consumer.name,
                              })).then(() => {
                                dispatch(loadBrokers())
                              })
                            }}
                          >Move</Button>
                        </Tooltip>
                      </Stack>
                    </ListItem>
                  )
                })}
              </AccordionDetails>
            </Accordion>
          )
        })
        }
      </List>
      <TextDialog
        text={text}
        setText={setText}
      />
    </>
  )
}

export default BrokersList
