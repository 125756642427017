import { type SearchUnitsOptionDto } from '../model/contracts/SearchUnitsOptionDto'

import { API_GATEWAY_ADDRESS } from './api'
import authService from './AuthService'
import { handleErrors } from './errorHandler'
import { msalFetch } from './graph'

export const CONFIGURATIONS_API_ADDRESS = `${API_GATEWAY_ADDRESS}/ec/configurations`

export class ConfigurationsApi {
  async getSearchOptions () {
    const identity = await authService.getIdentity()
    return await msalFetch()
      .get(`${CONFIGURATIONS_API_ADDRESS}/search`, identity.accessToken)
      .then(handleErrors)
      .then<SearchUnitsOptionDto>(async (res) => await res.json())
  }
}

const configurationsApi = new ConfigurationsApi()

export default configurationsApi
