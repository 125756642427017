import {
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react'
import z from 'zod'

import { setAuthTokenToHeaders } from 'shared/api/wretch/authorization'

import { API_GATEWAY_ADDRESS } from '../../../../../shared/api/api'

const PurposeSchema = z.object({
  id: z.number(),
  value: z.string(),
})

export type Purpose = z.infer<typeof PurposeSchema>

const PurposeRequestScheme = z.array(PurposeSchema).optional()

export enum PurposesApiMethods {
  getMany = 'getPurposes',
  getOne  = 'getPurpose',
  update  = 'updatePurpose',
  create  = 'createPurpose',
  delete  = 'deletePurpose',
}

const logisticsApi = `${API_GATEWAY_ADDRESS}/el`
export const purposesApi = createApi({
  reducerPath: 'purposesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: logisticsApi,
    prepareHeaders: async (headers: Headers) => await setAuthTokenToHeaders(headers),
  }),
  tagTypes: ['Purpose'],
  endpoints: (builder) => ({
    getPurposes: builder.query<Purpose[], undefined>({
      query: () => 'purposes',
      transformResponse: (response: Purpose[]) => {
        PurposeRequestScheme.parse(response)
        return response
      },
      providesTags: (result) =>
        typeof result !== 'undefined'
          ? [
            ...result.map(({
              id,
            }) => ({
              type: 'Purpose',
              id,
            } as const)),
            {
              type: 'Purpose',
              id: 'LIST',
            },
          ]
          : [
            {
              type: 'Purpose',
              id: 'LIST',
            },
          ],

    }),
    getPurpose: builder.query<Purpose[], number | string>({
      query: (id) => `purposes/${id}`,
      providesTags: (
        result, error,
        id) => [
        {
          type: 'Purpose',
          id,
        },
      ],
    }),

    createPurpose: builder.mutation<Purpose, Pick<Purpose, 'value'>>({
      query: ({
        value,
      }) => ({
        url: 'purposes',
        method: 'POST',
        body: { value },
      }),
    }),
    updatePurpose: builder.mutation<Purpose, Purpose>({
      query: ({
        id,
        value,
      }) => ({
        url: `purposes/${id}`,
        method: 'PUT',
        body: { value },
      }),
    }),
    deletePurpose: builder.mutation<Purpose, number>({
      query: (id) => ({
        url: `purposes/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useGetPurposesQuery,
  useUpdatePurposeMutation,
  useDeletePurposeMutation,
  useCreatePurposeMutation,
} = purposesApi
