import { z } from 'zod'

import { schemas } from '../commonECApiSchemes'

export type UnitSearchResponse = z.infer<typeof schemas.UnitsResponse>

export type SearchUnitDto = z.infer<typeof schemas.SearchUnitDto>
export type UnitDto = z.infer<typeof schemas.UnitDto>
export type AnalogsDto = z.infer<typeof schemas.UnitAnalogs>

export enum KdStatus {
  approved = 'approved',
  modelled = 'modelled',
  modeled  = 'modeled',
  proposed = 'proposed',
}

export enum UnitState {
  approved   = 'Approved',
  approving  = 'Approving',
  annuled    = 'Annuled',
  wrongState = 'WRONG STATUS',
}

export type ChildUnitDto = z.infer<typeof schemas.ChildUnitDto>

export type UnitDocumentDto = z.infer<typeof schemas.UnitDocumentDto>

export type ProductDataDto = z.infer<typeof schemas.ProductDataDto>

export type SubOemDto = z.infer<typeof schemas.SubOemDto>

export type StocksAvailabilityDto = z.infer<typeof schemas.StocksAvailabilityDto>

export type AvailableTransitDto = z.infer<typeof schemas.AvailableTransitDto>

export enum DocumentCategory {
  OutlineDrawings         = 1,
  InspectionDocumentation = 2,
  DesignDrawings          = 4,
  Models                  = 8,
}
