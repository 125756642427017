import { UnitVariant, UnitFilter } from '../../app/store/units/unitsFiltersSlice'
import { type InitailsFileDto } from '../model/contracts/InitialsFile'
import {
  type PrintDocumentsRequest,
  type PrintUnitsDocumentsRequest,
} from '../model/contracts/print-documents'
import { ReportDto } from '../model/contracts/ReportDto'
import { type UnitSearchResponse } from '../model/contracts/UnitDto'

import { API_GATEWAY_ADDRESS } from './api'
import authService from './AuthService'
import { handleErrors } from './errorHandler'
import { msalFetch } from './graph'

export const UNITS_API_ADDRESS = `${API_GATEWAY_ADDRESS}/ec/units`

export interface LoadInitialsResponse {
  files: InitailsFileDto[]
  error: string
}

export class UnitsApi {
  async getUnits (
    page: number, filter?: string, pageSize?: number,
    sortOrder?: string, filters?: UnitFilter[]) {
    const result = await authService.getIdentity()
    if (result) {
      return await msalFetch()
        .post(`${UNITS_API_ADDRESS}`, result.accessToken, {
          filter,
          page,
          pageSize,
          filters,
          sortOrder,
        })
        .then(handleErrors)
        .then<UnitSearchResponse>(async (res) => await res.json())
    }
  }

  async getReports () {
    const result = await authService.getIdentity()
    if (result) {
      return await msalFetch()
        .get(`${UNITS_API_ADDRESS}/report`, result.accessToken)
        .then(handleErrors)
        .then<ReportDto[]>(async (res) => await res.json())
    }
  }

  async downloadReport (filter: string, type: string, filters?: UnitFilter[], startDate?: string, endDate?: string) {
    const result = await authService.getIdentity()
    if (result) {
      return await msalFetch()
        .post(`${UNITS_API_ADDRESS}/report`, result.accessToken, {
          filter,
          filters,
          type,
          startDate,
          endDate,
        })
        .then(handleErrors)
        .then(async (res) => await res.blob())
    }
  }

  async downloadInspectionCards (filter: string, filters?: UnitFilter[]) {
    const result = await authService.getIdentity()
    return await msalFetch()
      .post(`${UNITS_API_ADDRESS}/files/inspectioncards`, result.accessToken, {
        filter,
        filters,
      })
      .then(handleErrors)
  }

  async downloadOutlineDrawings (filter: string, filters?: UnitFilter[]) {
    const result = await authService.getIdentity()
    return await msalFetch()
      .post(`${UNITS_API_ADDRESS}/files/drawings/outline`, result.accessToken, {
        filter,
        filters,
      })
      .then(handleErrors)
  }

  async print (unitId: number, request: PrintDocumentsRequest) {
    const result = await authService.getIdentity()
    return await msalFetch()
      .post(`${UNITS_API_ADDRESS}/${unitId}/print`, result.accessToken, request)
      .then(handleErrors)
  }

  async printDrawings (request: PrintUnitsDocumentsRequest) {
    const result = await authService.getIdentity()
    return await msalFetch().post(`${UNITS_API_ADDRESS}/print`, result.accessToken, request).then(handleErrors)
  }

  async printTypes (unitId: number) {
    const result = await authService.getIdentity()
    return await msalFetch()
      .get(`${UNITS_API_ADDRESS}/${unitId}/printtypes`, result.accessToken)
      .then(handleErrors)
      .then<string[]>(async (res) => await res.json())
  }

  async getInitialsContent (unitId: number) {
    const result = await authService.getIdentity()
    return await msalFetch()
      .get(`${UNITS_API_ADDRESS}/${unitId}/files/initials`, result.accessToken)
      .then(handleErrors)
      .then<LoadInitialsResponse>(async (res) => await res.json())
  }

  async downloadInitials (unitId: number, files: Array<{ fileId: number, fileName: string, version: number }>) {
    const result = await authService.getIdentity()
    return await msalFetch()
      .post(`${UNITS_API_ADDRESS}/${unitId}/files/initials/download`, result.accessToken, { files })
      .then(handleErrors)
  }

  async getVariants (filters: Array<{ key: string, value?: string }>) {
    const result = await authService.getIdentity()
    return await msalFetch()
      .post(`${UNITS_API_ADDRESS}/variants`, result.accessToken, { filters })
      .then(handleErrors)
      .then<UnitVariant[]>(async (res) => await res.json())
  }
}

const unitsApi = new UnitsApi()

export default unitsApi
