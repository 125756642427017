import wretch from 'wretch'

import { API_GATEWAY_ADDRESS } from '../api'
import authService from '../AuthService'

export const getWretchAuthorizedECApi = async () => {
  const token = await authService.getIdentity()

  return wretch(`${API_GATEWAY_ADDRESS}/el/`)
    .auth(`Bearer ${token.accessToken}`)
}
