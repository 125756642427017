import {
  DialogTitle, Divider, Stack, Dialog, DialogActions, DialogContent, Collapse,
  Button, Typography,
} from '@mui/material'
import { FC, SyntheticEvent } from 'react'

import { themeSpacingFromPixels } from '../../shared/lib/workWithThemeSpacing'

interface Props {
  open: boolean
  text?: string
  title?: string
  setOpen: (open: boolean) => void
  onConfirm: () => void
}

export const ConfirmationDialog: FC<Props> = ({
  open,
  text,
  title,
  setOpen,
  onConfirm,
}) => {
  const handleDialogClose = (_: SyntheticEvent, reason: string) => {
    if (reason && (reason === 'backdropClick' || reason === 'escapeKeyDown')) {
      setOpen(false)
    }
  }

  const handleDialogSelect = () => {
    onConfirm()
    setOpen(false)
  }

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { maxWidth: themeSpacingFromPixels(450) } }}
      open={open}
      onClose={handleDialogClose}
    >
      <DialogTitle>{title
        ? title
        : 'Confirm action'}</DialogTitle>
      <Divider/>
      <DialogContent>
        <Collapse in={true}>
          <Typography color="error">{text
            ? text
            : `Are you sure that you want to proceed the operation?`}</Typography>
        </Collapse>
      </DialogContent>
      <Divider/>
      <DialogActions>
        <Stack
          direction={'row'}
          spacing={1}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={handleDialogSelect}
          >
            Confirm
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setOpen(false)
            }}
          >
            Cancel
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}
