import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Box, Button, Stack, type SxProps, type Theme } from '@mui/material'
import Menu from '@mui/material/Menu'
import * as React from 'react'
import { PropsWithChildren } from 'react'

import { useFeatureSlicedDebug } from 'shared/lib/useFeatureSlicedDebug'

import { baseTheme } from '../../../../app/config/theme/baseTheme'

interface ButtonMenuProps extends PropsWithChildren {
  buttonTitle: string
  stopPropagation?: boolean
  sx?: SxProps<Theme>
}

function isBooleanArray (array: unknown): array is boolean[] {
  return Array.isArray(array) && array.every((val) => typeof val === 'boolean')
}

export const ButtonMenu: React.FC<ButtonMenuProps> = ({
  buttonTitle, children, sx, stopPropagation,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    if (stopPropagation) {
      event.stopPropagation()
    }
    setAnchorEl(event.currentTarget)
  }
  const {
    rootAttributes,
  } = useFeatureSlicedDebug('feature/DownloadOutline')

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    if (stopPropagation) {
      event.stopPropagation()
    }
    setAnchorEl(null)
  }

  if (isBooleanArray(children) && children.every(c => !c)) {
    return (
      <></>
    )
  }

  return (
    <>
      <Box
        {...rootAttributes}
        sx={{
          [baseTheme.breakpoints.down(baseTheme.breakpoints.values.md)]: { width: '100%' },
          [baseTheme.breakpoints.up(baseTheme.breakpoints.values.md)]: { width: 'inherit' },
        }}
      >
        <Button
          fullWidth
          data-testid="get-outline-drawings"
          variant="outlined"
          onClick={handleClickListItem}
          startIcon={<MoreVertIcon/>}
          sx={{
            height: '40px',
            ...sx,
          }}
        >
          {buttonTitle}
        </Button>
      </Box>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
      >
        <Stack
          sx={{
            marginLeft: 1,
            marginRight: 1,
          }}
          direction="column"
          gap={1}
        >

          {children}
        </Stack>
      </Menu>
    </>
  )
}
