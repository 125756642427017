import { BrokerDto } from '../model/contracts/brokers/BrockerDto'

import { API_GATEWAY_ADDRESS } from './api'
import authService from './AuthService'
import { handleErrors } from './errorHandler'
import { msalFetch } from './graph'

const BROKER_API_ULR = `${API_GATEWAY_ADDRESS}/ec/brokers`

export class BrokersApi {
  async loadBrokers () {
    const token = await authService.getIdentity()

    return await msalFetch()
      .get(BROKER_API_ULR, token.accessToken)
      .then(handleErrors)
      .then<BrokerDto[]>(async (res) => await res.json())
  }

  async restartConsumer (id: string, name: string) {
    const token = await authService.getIdentity()

    return await msalFetch()
      .put(`${BROKER_API_ULR}/${id}/consumers/${name}/restart`, token.accessToken)
      .then(handleErrors)
  }

  async stopConsumer (id: string, name: string) {
    const token = await authService.getIdentity()

    return await msalFetch()
      .put(`${BROKER_API_ULR}/${id}/consumers/${name}/stop`, token.accessToken)
      .then(handleErrors)
  }

  async moveConsumerMessageToError (id: string, name: string) {
    const token = await authService.getIdentity()

    return await msalFetch()
      .put(`${BROKER_API_ULR}/${id}/consumers/${name}/error`, token.accessToken)
      .then(handleErrors)
  }
}

const brokersApi = new BrokersApi()

export default brokersApi
