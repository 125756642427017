import {
  createAsyncThunk,
  createSlice,
  type SerializedError,
} from '@reduxjs/toolkit'

import unitsApi from 'shared/api/UnitsApi'

import { type RootState } from '../store'

import { UnitFilter } from './unitsFiltersSlice'

export interface UnitsInspectionCardsState {
  status: 'idle' | 'loading' | 'failed'
  blob?: string
  error?: SerializedError
}

const initialState: UnitsInspectionCardsState = { status: 'idle' }

export const downloadInspectionCards = createAsyncThunk('units-cards/download',
  async (request:
  {
    filter: string,
    filters?: UnitFilter[]
  }) => {
    return await unitsApi
      .downloadInspectionCards(request.filter, request.filters)
      .then((res) => {
        if (res.status === 204) {
          throw new Error('No inspection cards')
        }
        return res
      })
      .then(async (res) => await res.blob())
      .then((blob) => blob && window.URL.createObjectURL(blob))
  })

export const unitsInspectionCardsSlice = createSlice({
  name: 'units-cards',
  initialState,
  reducers: {
    clearInspectionsCardsBlob: (state) => {
      state.blob = undefined
    },
  },
  extraReducers: (build) => {
    build.addCase(downloadInspectionCards.pending, (state) => {
      state.status = 'loading'
      state.error = undefined
    })

    build.addCase(downloadInspectionCards.fulfilled, (
      state, action) => {
      state.status = 'idle'
      state.blob = action.payload
    })

    build.addCase(downloadInspectionCards.rejected, (
      state, action) => {
      state.status = 'idle'
      state.error = action.error
    })
  },
})

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUnitsInspectionCards = (state: RootState) => state.units.list.cards

export const {
  clearInspectionsCardsBlob,
} = unitsInspectionCardsSlice.actions

export default unitsInspectionCardsSlice.reducer
