import {
  Button,
  CircularProgress,
  Tooltip,
} from '@mui/material'
import { Box } from '@mui/system'
import { useSnackbar } from 'notistack'
import {
  type FC,
  useEffect,
  useState,
} from 'react'

import {
  checkOptions,
  checkPermission,
  type RootState,
  useAppSelector,
} from 'app/store'

import { type UnitTechnicalDescription } from 'entities/logistics/TechnicalCard'

import { OptionsUnitDocumentation } from 'shared/api/options'
import {
  UNITS_API_ADDRESS,
  UnitsApi,
} from 'shared/api/UnitsApi'
import {
  type ModuleName,
  useFeatureSlicedDebug,
} from 'shared/lib/useFeatureSlicedDebug'
import { useMobileBreakpoint } from 'shared/lib/useMobileBreakpoint'
import { themeSpacingFromPixels } from 'shared/lib/workWithThemeSpacing'
import { PermissionsDocumentationOutlineDownload } from 'shared/model/contracts/permissions'
import {
  DocumentCategory, SearchUnitDto,
  type UnitDocumentDto,
  type UnitSearchResponse,
} from 'shared/model/contracts/UnitDto'
import AuthenticatedDownloadLink from 'shared/ui/components/AuthenticatedDownloadLink'

interface DownloadODProps {
  sx?: Record<string, any>
  unitTD: UnitTechnicalDescription
}

export const DownloadOD: FC<DownloadODProps> = (
  {
    sx,
    unitTD,
  }) => {

  const mobileBreakpoint = useMobileBreakpoint()

  const [isHasOutlines, setIsHasOutlines] = useState(false)
  const {
    enqueueSnackbar,
  } = useSnackbar()

  const {
    rootAttributes,
  } = useFeatureSlicedDebug('feature/DownloadOD' as ModuleName)

  const canDownloadOutline = useAppSelector(
    (root: RootState) =>
      checkPermission(root, PermissionsDocumentationOutlineDownload) &&
      checkOptions(root, OptionsUnitDocumentation),
  )
  const [isDownloading, setIsDownloading] = useState(false)

  const handleDownloading = () => {
    setIsDownloading(true)
    enqueueSnackbar('Outlines download started', { variant: 'info' })
  }

  const handleDownloaded = () => {
    setIsDownloading(false)
    enqueueSnackbar('Outlines download succeed', { variant: 'success' })
  }

  const handleDownloadError = (error: Error) => {
    setIsDownloading(false)
    enqueueSnackbar('Error on Outlines download', { variant: 'error' })
  }

  const isUnitHasOutlines = async () => {

    const unitData: UnitSearchResponse | undefined = await new UnitsApi().getUnits(1, `${unitTD.elementId}`, 1, '', undefined)
    if (unitData?.items?.length) {
      const currUnit: SearchUnitDto = unitData.items[0]
      const outlineDrawingsCount = currUnit.documents
        ?.filter((v: UnitDocumentDto) => v
          .category === DocumentCategory.OutlineDrawings).length || 0
      if (outlineDrawingsCount > 0) {
        return true
      }
    }

    return false
  }

  useEffect(() => {
    const checkIsUnitHasOutlines = async () => {
      const iho = await isUnitHasOutlines()
      setIsHasOutlines(iho)
    }
    checkIsUnitHasOutlines().then(r => {
    })
  }, [])

  if (!canDownloadOutline) {
    return null
  }

  if (!isHasOutlines) {
    return null
  }

  return (
    <Box
      {...rootAttributes}

    >

      <AuthenticatedDownloadLink
        method="GET"
        url={`${UNITS_API_ADDRESS}/${unitTD.unitId}/files/categories/1`}
        filename={unitTD?.elementId || ''}
        isDownloading={isDownloading}
        onDownloading={handleDownloading}
        onDownloaded={handleDownloaded}
        onError={handleDownloadError}

      >
        <Tooltip title={'Download outlines'}>
          <Button
            sx={{
              width: mobileBreakpoint
                ? '100%'
                : themeSpacingFromPixels(100),
              height: themeSpacingFromPixels(40),
            }}
            startIcon={
              isDownloading &&
              <CircularProgress
                size={13}
              />
            }
            variant={'outlined'}
            disabled={isDownloading}
          >
            Outlines
          </Button>
        </Tooltip>
      </AuthenticatedDownloadLink>

    </Box>
  )
}

DownloadOD.displayName = 'DownloadOD'
