import {
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react'
import z from 'zod'

import { setAuthTokenToHeaders } from 'shared/api/wretch/authorization'

import { API_GATEWAY_ADDRESS } from '../../../../../shared/api/api'

const MaterialSchema = z.object({
  id: z.number(),
  value: z.string(),
})

export type Material = z.infer<typeof MaterialSchema>

const MaterialRequestScheme = z.array(MaterialSchema).optional()

export enum MaterialsApiMethods {
  getMany = 'getMaterials',
  getOne  = 'getMaterial',
  update  = 'updateMaterial',
  create  = 'createMaterial',
  delete  = 'deleteMaterial',
}

const logisticsApi = `${API_GATEWAY_ADDRESS}/el`

export const materialsApi = createApi({
  reducerPath: 'materialsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: logisticsApi,
    prepareHeaders: async (headers: Headers) => await setAuthTokenToHeaders(headers),
  }),
  tagTypes: ['Material'],
  endpoints: (builder) => ({
    getMaterials: builder.query<Material[], undefined>({
      query: () => 'materials',
      transformResponse: (response: Material[]) => {
        MaterialRequestScheme.parse(response)
        return response
      },
      providesTags: (result) =>
        typeof result !== 'undefined'
          ? [
            ...result.map(({
              id,
            }) => ({
              type: 'Material',
              id,
            } as const)),
            {
              type: 'Material',
              id: 'LIST',
            },
          ]
          : [
            {
              type: 'Material',
              id: 'LIST',
            },
          ],

    }),
    getMaterial: builder.query<Material[], number | string>({
      query: (id) => `materials/${id}`,
      providesTags: (
        result, error,
        id) => [
        {
          type: 'Material',
          id,
        },
      ],
    }),

    createMaterial: builder.mutation<Material, Pick<Material, 'value'>>({
      query: ({
        value,
      }) => ({
        url: 'materials',
        method: 'POST',
        body: { value },
      }),
    }),
    updateMaterial: builder.mutation<Material, Material>({
      query: ({
        id,
        value,
      }) => ({
        url: `materials/${id}`,
        method: 'PUT',
        body: { value },
      }),
    }),
    deleteMaterial: builder.mutation<Material, number>({
      query: (id) => ({
        url: `materials/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useGetMaterialsQuery,
  useUpdateMaterialMutation,
  useDeleteMaterialMutation,
  useCreateMaterialMutation,
} = materialsApi
