import {
  Button,
  CircularProgress,
} from '@mui/material'
import { type Theme } from '@mui/material/styles/createTheme'
import {
  Box,
  type SxProps,
} from '@mui/system'
import { type SerializedError } from '@reduxjs/toolkit'
import {
  type FC,
  useEffect,
} from 'react'

import { baseTheme } from 'app/config/theme/baseTheme'
import {
  addError,
  checkOptions,
  checkPermission,
  clearInspectionsCardsBlob,
  downloadInspectionCards,
  type RootState,
  selectUnitsFilters,
  selectUnitsInspectionCards,
  useAppDispatch,
  useAppSelector, type UnitsFilterState,
} from 'app/store'

import { OptionsUnitDocumentation } from 'shared/api/options'
import { useFeatureSlicedDebug } from 'shared/lib/useFeatureSlicedDebug'
import { PermissionsDocumentationInspectionDownload } from 'shared/model/contracts/permissions'
import { DownloadFileIcon } from 'shared/ui/Icons/DownloadFileIcon'

interface DownloadInspectionCardProps {
  sx?: SxProps<Theme>
}

export const DownloadInspectionCard: FC<DownloadInspectionCardProps> = ({
  sx,
}) => {

  const {
    rootAttributes,
  } = useFeatureSlicedDebug('feature/DownloadInspectionCard')

  const filtersState: UnitsFilterState = useAppSelector(selectUnitsFilters)
  const {
    unitFilter,
    prevFilters,
    unitFilters,
    nextFilters,
  } = filtersState
  const filters = [...prevFilters, ...unitFilters, ...nextFilters]
  const cardsState = useAppSelector(selectUnitsInspectionCards)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (cardsState?.blob) {
      const a = document.createElement('a')
      document.body.appendChild(a)
      a.href = cardsState?.blob
      a.download = 'Inspection cards.zip'
      a.click()
      document.body.removeChild(a)
      dispatch(clearInspectionsCardsBlob())
    }
  }, [cardsState?.blob])

  const canDownloadInspection = useAppSelector(
    (root: RootState) =>
      checkPermission(root, PermissionsDocumentationInspectionDownload) &&
      checkOptions(root, OptionsUnitDocumentation),
  )

  const handleDownloadInspectionCards = () => {
    dispatch(downloadInspectionCards({
      filter: unitFilter ?? '',
      filters, 
    }))
      .unwrap()
      .catch((e: SerializedError) => {
        dispatch(addError({
          message: e.message || 'An error occurred while loading cards',
          type: 'error',
        }))
      })
  }

  return (
    <Box
      {...rootAttributes}
      sx={{
        [baseTheme.breakpoints.down(baseTheme.breakpoints.values.md)]: { width: '100%' },
        [baseTheme.breakpoints.up(baseTheme.breakpoints.values.md)]: { width: 'inherit' },
      }}
    >
      {canDownloadInspection && (
        <Button
          fullWidth
          variant="outlined"
          disabled={cardsState.status === 'loading'}
          startIcon={cardsState.status === 'loading'
            ? <CircularProgress size={16}/>
            : <DownloadFileIcon/>}
          onClick={handleDownloadInspectionCards}
          sx={{
            height: '40px',
            ...sx,
          }}
        >
          Download found inspection cards
        </Button>
      )}
    </Box>
  )
}
