import {
  createAsyncThunk,
  createSlice,
  type PayloadAction,
  type SerializedError,
} from '@reduxjs/toolkit'

import brokersApi from '../../../shared/api/BrokersApi'
import { ConsumerDto } from '../../../shared/model/contracts/brokers/BrockerDto'
import { type RootState } from '../store'

export interface BrokerState {
  status: 'idle' | 'loading' | 'failed'
  brokers?: Broker[]
  pageError?: SerializedError
  actionError?: SerializedError
}

const initialState: BrokerState = { status: 'loading' }

export interface Consumer extends ConsumerDto {
  fetchStatus: 'idle' | 'loading'
}

export interface Broker {
  id: string
  name: string
  consumers?: Consumer[]
}

export const loadBrokers = createAsyncThunk('brokers/load', async () => {
  return await brokersApi.loadBrokers()
})

export const restartConsumer = createAsyncThunk('brokers/consumer/restart', async (request: {
  id: string,
  name: string
}) => {
  await brokersApi.restartConsumer(request.id, request.name)
})

export const stopConsumer = createAsyncThunk('brokers/consumer/stop', async (request: {
  id: string,
  name: string
}) => {
  await brokersApi.stopConsumer(request.id, request.name)
})

export const moveConsumerMessageToError = createAsyncThunk('brokers/consumer/error', async (request: {
  id: string,
  name: string
}) => {
  await brokersApi.moveConsumerMessageToError(request.id, request.name)
})

export const brokersSlice = createSlice({
  name: 'brokers',
  initialState,
  reducers: {
    showActionError: (state, action: PayloadAction<Error>) => {
      state.actionError = action.payload
    },
    setBrokers: (state, action: PayloadAction<Broker[] | undefined>) => {
      if (action.payload) {
        state.brokers = action.payload
      } else {
        state.brokers = undefined
      }
    },
  },
  extraReducers: (build) => {
    build.addCase(loadBrokers.pending, (state, action) => {
      state.status = 'loading'
    })

    build.addCase(loadBrokers.fulfilled, (state, action) => {
      state.brokers = action.payload.map(b => {
        return {
          id: b.id,
          name: b.name,
          consumers: b.consumers?.map(c => {
            return {
              id: c.id,
              name: c.name,
              isConnected: c.isConnected,
              isConnecting: c.isConnecting,
              isConsuming: c.isConsuming,
              statusInfo: c.statusInfo,
              fetchStatus: 'idle',
            }
          }),
        }
      })
      state.status = 'idle'
    })

    build.addCase(loadBrokers.rejected, (state, action) => {
      state.status = 'failed'
      state.pageError = action.error
    })

    build.addCase(restartConsumer.pending, (state, action) => {
      const broker = state.brokers?.find(b => b.id === action.meta.arg.id)
      const consumer = broker?.consumers?.find(c => c.name === action.meta.arg.name)
      if (consumer) {
        consumer.fetchStatus = 'loading'
      }
    })

    build.addCase(restartConsumer.fulfilled, (state, action) => {
      const broker = state.brokers?.find(b => b.id === action.meta.arg.id)
      const consumer = broker?.consumers?.find(c => c.name === action.meta.arg.name)
      if (consumer) {
        consumer.fetchStatus = 'idle'
      }
    })

    build.addCase(restartConsumer.rejected, (state, action) => {
      const broker = state.brokers?.find(b => b.id === action.meta.arg.id)
      const consumer = broker?.consumers?.find(c => c.name === action.meta.arg.name)
      if (consumer) {
        consumer.fetchStatus = 'idle'
      }
    })

    build.addCase(stopConsumer.pending, (state, action) => {
      const broker = state.brokers?.find(b => b.id === action.meta.arg.id)
      const consumer = broker?.consumers?.find(c => c.name === action.meta.arg.name)
      if (consumer) {
        consumer.fetchStatus = 'loading'
      }
    })

    build.addCase(stopConsumer.fulfilled, (state, action) => {
      const broker = state.brokers?.find(b => b.id === action.meta.arg.id)
      const consumer = broker?.consumers?.find(c => c.name === action.meta.arg.name)
      if (consumer) {
        consumer.fetchStatus = 'idle'
      }
    })

    build.addCase(stopConsumer.rejected, (state, action) => {
      const broker = state.brokers?.find(b => b.id === action.meta.arg.id)
      const consumer = broker?.consumers?.find(c => c.name === action.meta.arg.name)
      if (consumer) {
        consumer.fetchStatus = 'idle'
      }
    })

    build.addCase(moveConsumerMessageToError.pending, (state, action) => {
      const broker = state.brokers?.find(b => b.id === action.meta.arg.id)
      const consumer = broker?.consumers?.find(c => c.name === action.meta.arg.name)
      if (consumer) {
        consumer.fetchStatus = 'loading'
      }
    })

    build.addCase(moveConsumerMessageToError.fulfilled, (state, action) => {
      const broker = state.brokers?.find(b => b.id === action.meta.arg.id)
      const consumer = broker?.consumers?.find(c => c.name === action.meta.arg.name)
      if (consumer) {
        consumer.fetchStatus = 'idle'
      }
    })

    build.addCase(moveConsumerMessageToError.rejected, (state, action) => {
      const broker = state.brokers?.find(b => b.id === action.meta.arg.id)
      const consumer = broker?.consumers?.find(c => c.name === action.meta.arg.name)
      if (consumer) {
        consumer.fetchStatus = 'idle'
      }
    })

  },
})

export const {
  showActionError,
  setBrokers,
} = brokersSlice.actions

export const selectBrokers = (state: RootState) => state.administration.brokers

export default brokersSlice.reducer
