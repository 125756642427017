import { ArrowBackIos } from '@mui/icons-material'
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'
import Skeleton from '@mui/material/Skeleton'
import { type FunctionComponent, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  hideActionError,
  loadSettings,
  selectSettings,
} from 'app/store/administration/settingsSlice'
import {
  useAppDispatch,
  useAppSelector,
} from 'app/store/hooks'

import ErrorBox from 'shared/ui/components/ErrorBox'
import ErrorDialog from 'shared/ui/components/ErrorDialog'

import { loadBrokers } from '../app/store/administration/brokerSlice'
import BrokersCard from '../widgets/administration/brokers/BrokersCard'

const BrokersPage: FunctionComponent = (props) => {
  const navigate = useNavigate()
  const settingsState = useAppSelector(selectSettings)
  const {
    status,
    pageError,
    actionError,
  } = settingsState
  const dispatch = useAppDispatch()

  const idle = status === 'idle'
  const loading = status === 'loading'
  const saving = status === 'saving'
  const failed = status === 'failed'

  useEffect(() => {
    dispatch(loadBrokers())
  }, [dispatch])

  return (
    <Box mt={6}>
      <Container maxWidth="lg">
        {(idle || loading || saving) && (
          <>
            <Box mb={2}>
              <Grid
                container
                spacing={3}
                alignItems="center"
              >
                <Grid item>
                  <Tooltip title="Home">
                    <IconButton onClick={() => {
                      navigate('/')
                    }}
                    >
                      <ArrowBackIos/>
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Typography variant="h5">{loading
                    ? <Skeleton width={50}/>
                    : 'Broker actions'}</Typography>
                </Grid>
                <Grid
                  item
                  xs
                />
              </Grid>
            </Box>

            <Box mt={3}>
              <Grid
                container
                spacing={3}
                alignItems="stretch"
              >
                <Grid
                  item
                  xs={12}
                  mt={6}
                >
                  <BrokersCard/>
                </Grid>
              </Grid>
            </Box>
          </>
        )}

        {failed && pageError && (
          <ErrorBox
            code={pageError.name}
            description={pageError.message}
            sx={{ minHeight: '90%' }}
          >
            <Button
              variant="contained"
              onClick={async () => await dispatch(loadSettings())}
            >
              Try again
            </Button>
          </ErrorBox>
        )}
      </Container>

      <ErrorDialog
        open={actionError !== undefined}
        title={actionError?.name || 'Error'}
        text={actionError?.message ?? ''}
        onOk={() => dispatch(hideActionError())}
      />
    </Box>
  )
}

export default BrokersPage
