import {
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react'
import z from 'zod'

import { setAuthTokenToHeaders } from 'shared/api/wretch/authorization'

import { API_GATEWAY_ADDRESS } from '../../../../../shared/api/api'

const EquipmentTypeSchema = z.object({
  id: z.number(),
  value: z.string(),
})

export type EquipmentType = z.infer<typeof EquipmentTypeSchema>

const EquipmentTypeRequestScheme = z.array(EquipmentTypeSchema).optional()

export enum EquipmentTypesApiMethods {
  getMany = 'getEquipmentTypes',
  getOne  = 'getEquipmentType',
  update  = 'updateEquipmentType',
  create  = 'createEquipmentType',
  delete  = 'deleteEquipmentType',
}

const logisticsApi = `${API_GATEWAY_ADDRESS}/el`

export const equipmentTypesApi = createApi({
  reducerPath: 'equipmentTypesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: logisticsApi,
    prepareHeaders: async (headers: Headers) => await setAuthTokenToHeaders(headers),
  }),
  tagTypes: ['EquipmentType'],
  endpoints: (builder) => ({
    getEquipmentTypes: builder.query<EquipmentType[], undefined>({
      query: () => 'equipment-types',
      transformResponse: (response: EquipmentType[]) => {
        EquipmentTypeRequestScheme.parse(response)
        return response
      },
      providesTags: (result) =>
        typeof result !== 'undefined'
          ? [
            ...result.map(({
              id,
            }) => ({
              type: 'EquipmentType',
              id,
            } as const)),
            {
              type: 'EquipmentType',
              id: 'LIST',
            },
          ]
          : [
            {
              type: 'EquipmentType',
              id: 'LIST',
            },
          ],

    }),
    getEquipmentType: builder.query<EquipmentType[], number | string>({
      query: (id) => `equipment-types/${id}`,
      providesTags: (
        result,
        error,
        id) => [
        {
          type: 'EquipmentType',
          id,
        },
      ],
    }),

    createEquipmentType: builder.mutation<EquipmentType, Pick<EquipmentType, 'value'>>({
      query: ({
        value,
      }) => ({
        url: 'equipment-types',
        method: 'POST',
        body: { value },
      }),
    }),
    updateEquipmentType: builder.mutation<EquipmentType, EquipmentType>({
      query: ({
        id,
        value,
      }) => ({
        url: `equipment-types/${id}`,
        method: 'PUT',
        body: { value },
      }),
    }),
    deleteEquipmentType: builder.mutation<EquipmentType, number>({
      query: (id) => ({
        url: `equipment-types/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
})

export const {
  useGetEquipmentTypesQuery,
  useUpdateEquipmentTypeMutation,
  useDeleteEquipmentTypeMutation,
  useCreateEquipmentTypeMutation,
} = equipmentTypesApi
