import { type UpdateSettingDto, type AppSettingDto } from '../model/contracts/SettingsDto'

import { API_GATEWAY_ADDRESS } from './api'
import authService from './AuthService'
import { handleErrors } from './errorHandler'
import { msalFetch } from './graph'

const GET_SETTINGS_URL = `${API_GATEWAY_ADDRESS}/ec/administration/settings/list`
const SAVE_SETTINGS_URL = `${API_GATEWAY_ADDRESS}/ec/administration/settings`

export class SettingsApi {
  async getSettings () {
    const token = await authService.getIdentity()

    return await msalFetch()
      .get(GET_SETTINGS_URL, token.accessToken)
      .then(handleErrors)
      .then<AppSettingDto[]>(async (res) => await res.json())
  }

  async saveOptions (settings: UpdateSettingDto[]) {
    const token = await authService.getIdentity()

    return await msalFetch()
      .put(`${SAVE_SETTINGS_URL}`, token.accessToken, { settings })
      .then(handleErrors)
      .then<AppSettingDto[]>(async (res) => await res.json())
  }
}

const settingsApi = new SettingsApi()

export default settingsApi
