import { type OptionDto } from '../../model/contracts/administration/Options'
import { API_GATEWAY_ADDRESS } from '../api'
import authService from '../AuthService'
import { handleErrors } from '../errorHandler'
import { msalFetch } from '../graph'

const OPTIONS_API_ADDRESS = `${API_GATEWAY_ADDRESS}/ec/administration/options`

export class OptionsApi {
  async getOptions () {
    const token = await authService.getIdentity()

    return await msalFetch()
      .get(OPTIONS_API_ADDRESS, token.accessToken)
      .then(handleErrors)
      .then<OptionDto[]>(async (res) => await res.json())
  }
}

const rightsApi = new OptionsApi()

export default rightsApi
