import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { setAuthTokenToHeaders } from 'shared/api/wretch/authorization'
import { schemas } from 'shared/model/commonLogisticApiSchemes'

import { API_GATEWAY_ADDRESS } from '../../../../shared/api/api'

import { QualityImageItemPaginatedResponse } from './types'

type QualityImageRequest = {
  unitId: number,
  page?: number,
  pageSize?: number,
}

const logisticsApi = `${API_GATEWAY_ADDRESS}/el`

export const qaPreviewDataApi = createApi({
  reducerPath: 'qaPreviewDataApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${logisticsApi}/units`,
    prepareHeaders: async (headers: Headers) => await setAuthTokenToHeaders(headers),
  }),
  endpoints: (builder) => ({
    getPreviewData: builder.query<QualityImageItemPaginatedResponse, QualityImageRequest>({
      query: ({
        unitId, page, pageSize,
      }) => {
        const getParams: Record<string, string> = {}
        if (page) {
          getParams['page'] = page.toString()
        }
        if (pageSize) {
          getParams['pageSize'] = pageSize.toString()
        }
        const queryParams = new URLSearchParams(getParams).toString()

        return queryParams
          ? `/${unitId}/qa/images?${queryParams}`
          : `/${unitId}/qa/images`
      },
      transformResponse: (response: QualityImageItemPaginatedResponse): QualityImageItemPaginatedResponse => {
        schemas.QualityImageItemPaginatedResponse.parse(response)
        return response
      },
      transformErrorResponse: (errorResponse) => {
        return errorResponse
      },
      keepUnusedDataFor: 0,
      providesTags: [],
    }),
  }),
})
