import { type OptionDto } from '../../model/contracts/administration/Options'
import { type RightDto } from '../../model/contracts/administration/Rights'
import { type RoleDto, type RoleDto as RoleItemDto } from '../../model/contracts/administration/Roles'
import { type UserDto } from '../../model/contracts/administration/Users'
import { API_GATEWAY_ADDRESS } from '../api'
import authService from '../AuthService'
import { handleErrors } from '../errorHandler'
import { msalFetch } from '../graph'

const ROLES_API_ADDRESS = `${API_GATEWAY_ADDRESS}/ec/administration/roles`

export class RolesApi {
  async getRoles () {
    const token = await authService.getIdentity()

    return await msalFetch()
      .get(ROLES_API_ADDRESS, token.accessToken)
      .then(handleErrors)
      .then<RoleItemDto[]>(async (res) => await res.json())
  }

  async getRole (roleId: number | string) {
    const token = await authService.getIdentity()

    return await msalFetch()
      .get(`${ROLES_API_ADDRESS}/${roleId}`, token.accessToken)
      .then(handleErrors)
      .then<RoleDto>(async (res) => await res.json())
  }

  async addUsers (roleId: number | string, users: string[]) {
    const token = await authService.getIdentity()

    return await msalFetch()
      .post(`${ROLES_API_ADDRESS}/${roleId}/users`, token.accessToken, { users })
      .then(handleErrors)
      .then<UserDto[]>(async (res) => await res.json())
  }

  async removeUser (roleId: number | string, userId: string) {
    const token = await authService.getIdentity()

    return await msalFetch()
      .delete(`${ROLES_API_ADDRESS}/${roleId}/users/${userId}`, token.accessToken)
      .then(handleErrors)
  }

  async saveRights (roleId: number | string, rights: number[]) {
    const token = await authService.getIdentity()

    return await msalFetch()
      .put(`${ROLES_API_ADDRESS}/${roleId}/rights`, token.accessToken, { rights })
      .then(handleErrors)
      .then<RightDto[]>(async (res) => await res.json())
  }

  async saveOptions (roleId: number | string, options: number[]) {
    const token = await authService.getIdentity()

    return await msalFetch()
      .put(`${ROLES_API_ADDRESS}/${roleId}/options`, token.accessToken, { options })
      .then(handleErrors)
      .then<OptionDto[]>(async (res) => await res.json())
  }

  async createRole () {
    const token = await authService.getIdentity()

    return await msalFetch()
      .post(`${ROLES_API_ADDRESS}`, token.accessToken)
      .then(handleErrors)
      .then<RoleDto>(async (res) => await res.json())
  }

  async updateRole (roleId: number | string, data: { name: string }) {
    const token = await authService.getIdentity()

    return await msalFetch().put(`${ROLES_API_ADDRESS}/${roleId}`, token.accessToken, data).then(handleErrors)
  }

  async deleteRole (roleId: number | string) {
    const token = await authService.getIdentity()
    return await msalFetch().delete(`${ROLES_API_ADDRESS}/${roleId}`, token.accessToken).then(handleErrors)
  }
}

const rolesApi = new RolesApi()

export default rolesApi
