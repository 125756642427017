import { UnitModel } from '../../app/store/units/createUnitDialogSlice'
import { CreateEtoUnitValidationResponse } from '../model/contracts/CreateEtoUnitValidationResponse'
import { CreateUnitValidationResponse } from '../model/contracts/CreateUnitValidationResponse'

import { API_GATEWAY_ADDRESS } from './api'
import authService from './AuthService'
import { handleErrors } from './errorHandler'
import { msalFetch } from './graph'

export interface CreateUnitValidationRequest {
  equipmentId: number | null,
  productGroup: string | null,
  productKey: string | null
  consumptionCategory: number | null,
  type: string | null,
  oemNumber: string | null,
  nameEn: string | null,
  nameRu: string | null,
  productionName: string | null,
  productLineNotesEn: string | null,
  productLineNotesRu: string | null,
  length: number | null,
  widthOrDiameter: number | null,
  height: number | null,
  weight: number | null,
  material: string | null,
  unitOfMeasurement: string | null,
}

export interface CreateEtoUnitValidationRequest {
  projectId: number | null,
  customer: string | null,
  productGroup: string | null,
  productKey: string | null,
  consumptionCategory: number | null,
  type: string | null,
  oemNumber: string | null,
  nameEn: string | null,
  nameRu: string | null,
  productionName: string | null,
  productLineNotesEn: string | null,
  productLineNotesRu: string | null,
  length: number | null,
  widthOrDiameter: number | null,
  height: number | null,
  weight: number | null,
  material: string | null,
  unitOfMeasurement: string | null,
}

export class CreateUnitValidationApi {
  async validateUnit (unitModel: UnitModel): Promise<CreateUnitValidationResponse> {
    const result = await authService.getIdentity()

    if (unitModel.type === 'unit') {
      const unitValidationRequest: CreateUnitValidationRequest = {
        equipmentId: unitModel.equipmentModel?.id ?? null,
        productGroup: unitModel.productGroupModel?.code ?? null,
        productKey: unitModel.productKeyModel?.code ?? null,
        consumptionCategory: unitModel.consumptionCategory?.id ?? null,
        type: unitModel.oemType ?? null,
        oemNumber: unitModel.oemNumber ?? null,
        nameEn: unitModel.nameEn ?? null,
        nameRu: unitModel.nameRus ?? null,
        productionName: unitModel.productionName ?? null,
        productLineNotesEn: unitModel.plNotesEn ?? null,
        productLineNotesRu: unitModel.plNotesRus ?? null,
        length: Number(unitModel.length) ?? null,
        widthOrDiameter: Number(unitModel.widthOrDiameter) ?? null,
        height: Number(unitModel.height) ?? null,
        weight: Number(unitModel.weight) ?? null,
        material: unitModel.materialModel?.materialId ?? null,
        unitOfMeasurement: unitModel.unitsOfMeasure?.id ?? null,
      }
      return await msalFetch()
        .post(`${API_GATEWAY_ADDRESS}/uv/validation/units/create`, result.accessToken, unitValidationRequest)
        .then(handleErrors)
        .then<CreateUnitValidationResponse>(res => res.json())

    } else if (unitModel.type === 'etoUnit') {

      const etoUnitValidationRequest: CreateEtoUnitValidationRequest = {
        projectId: unitModel.projectModel?.id ?? null,
        customer: unitModel.projectModel?.customer ?? null,
        productGroup: unitModel.productGroupModel?.code ?? null,
        productKey: unitModel.productKeyModel?.code ?? null,
        consumptionCategory: unitModel.consumptionCategory?.id ?? null,
        type: unitModel.oemType ?? null,
        oemNumber: unitModel.oemNumber ?? null,
        nameEn: unitModel.nameEn ?? null,
        nameRu: unitModel.nameRus ?? null,
        productionName: unitModel.productionName ?? null,
        productLineNotesEn: unitModel.plNotesEn ?? null,
        productLineNotesRu: unitModel.plNotesRus ?? null,
        length: Number(unitModel.length) ?? null,
        widthOrDiameter: Number(unitModel.widthOrDiameter) ?? null,
        height: Number(unitModel.height) ?? null,
        weight: Number(unitModel.weight) ?? null,
        material: unitModel.materialModel?.materialId ?? null,
        unitOfMeasurement: unitModel.unitsOfMeasure?.id ?? null,
      }

      return await msalFetch()
        .post(`${API_GATEWAY_ADDRESS}/uv/validation/eto-units/create`, result.accessToken, etoUnitValidationRequest)
        .then(handleErrors)
        .then<CreateEtoUnitValidationResponse>(res => res.json())
    }
    throw new Error(`unknown unit type: ${unitModel.type}`)
  }
}

const createUnitValidationApi = new CreateUnitValidationApi()

export default createUnitValidationApi
