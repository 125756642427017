import * as HttpStatus from 'http-status-codes'

export async function handleErrors (response: Response, message?: string) {
  if (!response.ok) {
    const error = new Error()
    if (response.status) {
      error.name = `${response.status.toString()} ${HttpStatus.getStatusText(response.status)}`
      error.message = message || (await response.text()) || HttpStatus.getStatusText(response.status)
    } else {
      error.name = 'Fetch error'
      error.message = 'Error occurred while trying to fetch request'
    }
    throw error
  }

  return response
}
