import {
  createSlice,
  type SerializedError, type PayloadAction, createAsyncThunk,
} from '@reduxjs/toolkit'

import createUnitApi from '../../../shared/api/CreateUnitApi'
import createUnitValidationApi from '../../../shared/api/CreateUnitValidationApi'
import { ConsumptionCategoryDto } from '../../../shared/model/contracts/ConsumptionCategoryDto'
import { CreateEtoUnitValidationResponse } from '../../../shared/model/contracts/CreateEtoUnitValidationResponse'
import { CreateUnitValidationResponse } from '../../../shared/model/contracts/CreateUnitValidationResponse'
import { ProductGroupDto } from '../../../shared/model/contracts/ProductGroupDto'
import { UnitOfMeasureDto } from '../../../shared/model/contracts/UnitOfMeasureDto'
import { type RootState } from '../store'

const name = 'create-unit-dialog'

export interface CreateUnitDialogState {
  open: boolean
  fetchStatus: 'idle' | 'pending' | 'rejected'
  steps: DialogStep[],
  createData: CreateData,
  model: UnitModel,
  validationData: ValidationData
  error?: SerializedError
}

export interface DialogStep {
  active: boolean
  completed: boolean
  name: string
}

export type UnitType = 'unit' | 'etoUnit' | undefined;
export type OemType = 'oem' | 'element' | undefined;

export interface CreateData {
  productGroups?: ProductGroupDto[]
  selectedProductGroup?: ProductGroupDto
  unitsOfMeasure?: UnitOfMeasureDto[]
  consumptionCategories?: ConsumptionCategoryDto[]
}

export interface ValidationData {
  unitValidation?: CreateUnitValidationResponse
  etoUnitValidation?: CreateEtoUnitValidationResponse
}

export interface UnitModel {
  equipmentModel?: EquipmentModel
  projectModel?: ProjectModel
  customerModel?: CustomerModel
  productGroupModel?: ProductGroupModel
  productKeyModel?: ProductKeyModel
  materialModel?: MaterialModel
  unitsOfMeasure?: UnitOfMeasureModel
  consumptionCategory?: ConsumptionCategoryModel
  type?: UnitType
  oemType?: OemType
  oemNumber?: string
  nameEn?: string
  nameRus?: string
  productionName?: string
  plNotesEn?: string
  plNotesRus?: string
  doNotShowEparts: boolean
  notForSmNf: boolean
  material?: string
  length?: string
  widthOrDiameter?: string
  height?: string
  weight?: string
  oversized: boolean
}

export interface EquipmentModel {
  id: number
  oemEquipmentName?: string
}

export interface MaterialModel {
  id: number
  name?: string
  materialId?: string
}

export interface ProjectModel {
  id: number
  projectName?: string
  customer?: string
}

export interface CustomerModel {
  id: number
  name?: string
}

export interface ProductGroupModel {
  id: number
  code: string
}

export interface ConsumptionCategoryModel {
  id: number
  name: string
}

export interface UnitOfMeasureModel {
  id: string
  name: string
}

export interface ProductKeyModel {
  id: number
  code: string
  name: string
}

export const loadProductGroups = createAsyncThunk(`${name}/loadProductGroups`, async () => {
  return await createUnitApi.loadProductGroups()
})

export const loadConsumptionCategories = createAsyncThunk(`${name}/loadConsumptionCategories `, async () => {
  return await createUnitApi.loadConsumptionCategories()
})

export const loadUnitsOfMeasure = createAsyncThunk(`${name}/loadUnitsOfMeasure `, async () => {
  return await createUnitApi.loadUnitsOfMeasure()
})

export const validateUnit = createAsyncThunk(`${name}/validateUnit `, async (unitModel: UnitModel) => {
  return await createUnitValidationApi.validateUnit(unitModel)
})

export const submitUnit = createAsyncThunk(`${name}/unitCreateRequests `, async (unitModel: UnitModel) => {
  return await createUnitApi.submitUnit(unitModel)
})

const initialState: CreateUnitDialogState = {
  open: false,
  fetchStatus: 'idle',
  createData: {
    productGroups: undefined,
    selectedProductGroup: undefined,
  },
  model: {
    type: undefined,
    oemType: undefined,
    oemNumber: undefined,
    nameEn: undefined,
    nameRus: undefined,
    productionName: undefined,
    plNotesEn: undefined,
    plNotesRus: undefined,
    doNotShowEparts: false,
    notForSmNf: false,
    length: undefined,
    widthOrDiameter: undefined,
    height: undefined,
    weight: undefined,
    oversized: false,
    unitsOfMeasure: undefined,
  },
  validationData: {},
  steps: [
    {
      active: true,
      completed: false,
      name: 'Equipment',
    },
    {
      active: false,
      completed: false,
      name: 'Mandatory fields',
    },
    {
      active: false,
      completed: false,
      name: 'Other fields',
    },
  ],
}

const slice = createSlice({
  name,
  initialState,
  reducers: {
    openUnitCreate: (_) => {
      return {
        ...initialState,
        open: true,
      }
    },

    closeUnitCreate: (_) => {
      return {
        ...initialState,
        open: false,
      }
    },

    moveNext: (state) => {
      const cur = state.steps.find(s => s.active)
      const index = state.steps.findIndex(s => s.active)
      if (cur) {
        if (index < state.steps.length - 1) {
          cur.active = false
          cur.completed = true
          const next = state.steps[index + 1]
          next.active = true
        }
      }
    },

    moveBack: (state) => {
      const cur = state.steps.find(s => s.active)
      const index = state.steps.findIndex(s => s.active)

      if (cur) {
        if (index > 0) {
          cur.active = false
          cur.completed = false
          const prev = state.steps[index - 1]
          prev.active = true
          prev.completed = false
        }
      }
    },

    moveToStep: (state, action: PayloadAction<string>) => {
      const curIndex = state.steps.findIndex(s => s.active)
      const targetIndex = state.steps.findIndex(s => s.name == action.payload)

      if (curIndex !== targetIndex) {
        if (curIndex > targetIndex) {
          for (let i = 0; i < curIndex - targetIndex; i++) {
            const cur = state.steps.find(s => s.active)
            const index = state.steps.findIndex(s => s.active)

            if (cur) {
              if (index > 0) {
                cur.active = false
                cur.completed = false
                const prev = state.steps[index - 1]
                prev.active = true
                prev.completed = false
              }
            }
          }
        } else {
          for (let i = 0; i < targetIndex - curIndex; i++) {
            const cur = state.steps.find(s => s.active)
            const index = state.steps.findIndex(s => s.active)
            if (cur) {
              if (index < state.steps.length - 1) {
                cur.active = false
                cur.completed = true
                const next = state.steps[index + 1]
                next.active = true
              }
            }
          }
        }
      }
    },

    selectType: (state, action: PayloadAction<UnitType>) => {
      state.model.type = action.payload
    },

    selectOem: (state, action: PayloadAction<OemType>) => {
      state.model.oemType = action.payload
    },

    setDoNotShow: (state, action: PayloadAction<boolean>) => {
      state.model.doNotShowEparts = action.payload
    },

    setNotForEparts: (state, action: PayloadAction<boolean>) => {
      state.model.notForSmNf = action.payload
    },

    setOemNumber: (state, action: PayloadAction<string>) => {
      state.model.oemNumber = action.payload
    },

    setNameEn: (state, action: PayloadAction<string>) => {
      state.model.nameEn = action.payload
    },

    setNameRus: (state, action: PayloadAction<string>) => {
      state.model.nameRus = action.payload
    },

    setProductionName: (state, action: PayloadAction<string>) => {
      state.model.productionName = action.payload
    },

    setProductLineNotesEn: (state, action: PayloadAction<string>) => {
      state.model.plNotesEn = action.payload
    },

    setProductLineNotesRus: (state, action: PayloadAction<string>) => {
      state.model.plNotesRus = action.payload
    },
    setLength: (state, action: PayloadAction<string>) => {
      state.model.length = action.payload
    },
    setWidthOrDiameter: (state, action: PayloadAction<string>) => {
      state.model.widthOrDiameter = action.payload
    },
    setHeight: (state, action: PayloadAction<string>) => {
      state.model.height = action.payload
    },
    setWeight: (state, action: PayloadAction<string>) => {
      state.model.weight = action.payload
    },
    setOversized: (state, action: PayloadAction<boolean>) => {
      state.model.oversized = action.payload
    },
    setUnitsOfMeasure: (state, action: PayloadAction<string | undefined>) => {
      if (action.payload === undefined) {
        state.model.unitsOfMeasure = undefined
        return
      }

      const unitsOfMeasure = state.createData?.unitsOfMeasure?.find(uom => uom.name === action.payload)
      if (unitsOfMeasure) {
        state.model.unitsOfMeasure = {
          id: unitsOfMeasure.id ?? '',
          name: unitsOfMeasure.name ?? '',
        }
      }
    },
    setConsumptionCategory: (state, action: PayloadAction<string | undefined>) => {
      if (action.payload === undefined) {
        state.model.consumptionCategory = undefined
        return
      }
      const consCat = action.payload.split(' - ')[0]

      const consumptionCategory = state.createData.consumptionCategories?.find(cc => cc?.id?.toString() === consCat)
      if (consumptionCategory) {
        state.model.consumptionCategory = {
          id: consumptionCategory.id ?? 0,
          name: consumptionCategory.name ?? '',
        }
      }
    },
    setEquipmentModel: (state, action: PayloadAction<EquipmentModel | undefined>) => {
      state.model.equipmentModel = action.payload
    },
    setMaterialModel: (state, action: PayloadAction<MaterialModel | undefined>) => {
      state.model.materialModel = action.payload
    },
    setProjectModel: (state, action: PayloadAction<ProjectModel | undefined>) => {
      state.model.projectModel = action.payload
    },
    setCustomerModel: (state, action: PayloadAction<CustomerModel | undefined>) => {
      state.model.customerModel = action.payload
    },
    selectProductGroup: (state, action: PayloadAction<string>) => {
      const productGroup = state.createData.productGroups?.find(pg => pg.pgCode === action.payload)
      if (productGroup) {
        state.createData.selectedProductGroup = productGroup
        state.model.productGroupModel = {
          id: productGroup.id ?? 0,
          code: productGroup.pgCode ?? '',
        }
        state.model.productKeyModel = undefined
      }
    },
    selectProductKey: (state, action: PayloadAction<string>) => {
      if (state.createData.selectedProductGroup) {
        const key = action.payload.split(' - ')[0]
        const productKey = state.createData.selectedProductGroup?.productKeys?.find(pk => pk.pkCode === key)
        if (productKey) {
          state.model.productKeyModel = {
            id: productKey.id ?? 0,
            code: productKey.pkCode ?? '',
            name: productKey.pkName ?? '',
          }
        }
      }
    },

    clearProductKey: (state) => {
      state.model.productKeyModel = undefined
    },

    clearValidation: (state) => {
      state.validationData.unitValidation = undefined
      state.validationData.etoUnitValidation = undefined
    },

  },
  extraReducers: (build) => {
    build.addCase(loadProductGroups.pending, (state) => {
      return {
        ...state,
        fetchStatus: 'pending',
      }
    })

    build.addCase(loadProductGroups.fulfilled, (state, action) => {
      state.createData.productGroups = action.payload
      state.fetchStatus = 'idle'
    })

    build.addCase(loadProductGroups.rejected, (state, payload) => {
      return {
        ...state,
        fetchStatus: 'pending',
        error: payload.error,
      }
    })

    build.addCase(loadConsumptionCategories.pending, (state) => {
      return {
        ...state,
        fetchStatus: 'pending',
      }
    })

    build.addCase(loadConsumptionCategories.fulfilled, (state, action) => {
      state.createData.consumptionCategories = action.payload
      state.fetchStatus = 'idle'
    })

    build.addCase(loadConsumptionCategories.rejected, (state, payload) => {
      return {
        ...state,
        fetchStatus: 'pending',
        error: payload.error,
      }
    })

    build.addCase(loadUnitsOfMeasure.pending, (state) => {
      return {
        ...state,
        fetchStatus: 'pending',
      }
    })

    build.addCase(loadUnitsOfMeasure.fulfilled, (state, action) => {
      state.createData.unitsOfMeasure = action.payload
      state.fetchStatus = 'idle'
    })

    build.addCase(loadUnitsOfMeasure.rejected, (state, payload) => {
      return {
        ...state,
        fetchStatus: 'pending',
        error: payload.error,
      }
    })

    build.addCase(validateUnit.pending, (state) => {
      return {
        ...state,
        fetchStatus: 'pending',
      }
    })

    build.addCase(validateUnit.fulfilled, (state, action) => {
      if (action.meta.arg.type === 'unit') {
        state.validationData.unitValidation = action.payload
      }
      if (action.meta.arg.type === 'etoUnit') {
        state.validationData.etoUnitValidation = action.payload
      }

      state.fetchStatus = 'idle'
    })

    build.addCase(validateUnit.rejected, (state, payload) => {
      return {
        ...state,
        fetchStatus: 'pending',
        error: payload.error,
      }
    })

    build.addCase(submitUnit.pending, (state) => {
      return {
        ...state,
        fetchStatus: 'pending',
      }
    })

    build.addCase(submitUnit.fulfilled, (state, action) => {
      state.fetchStatus = 'idle'
    })

    build.addCase(submitUnit.rejected, (state, payload) => {
      return {
        ...state,
        fetchStatus: 'pending',
        error: payload.error,
      }
    })
  },
})

export const {
  openUnitCreate,
  closeUnitCreate,
  moveNext,
  moveBack,
  moveToStep,
  selectType,
  selectOem,
  setDoNotShow,
  setNotForEparts,
  setOemNumber,
  setNameEn,
  setNameRus,
  setProductionName,
  setProductLineNotesEn,
  setProductLineNotesRus,
  setLength,
  setWidthOrDiameter,
  setHeight,
  setWeight,
  setOversized,
  setUnitsOfMeasure,
  setConsumptionCategory,
  setEquipmentModel,
  setMaterialModel,
  setProjectModel,
  selectProductGroup,
  selectProductKey,
  clearValidation,
  clearProductKey,
} = slice.actions

export const selectCreateUnitDialogState = (state: RootState) => state.units.list.createUnitDialog
export const selectCurrentStep = (state: RootState) => state.units.list.createUnitDialog.steps.find(s => s.active)
export const selectCurrentIndex = (state: RootState) => state.units.list.createUnitDialog.steps.findIndex(s => s.active)
export const selectUnitModel = (state: RootState) => state.units.list.createUnitDialog.model
export const selectEquipmentModel = (state: RootState) => state.units.list.createUnitDialog.model.equipmentModel
export const selectMaterialModel = (state: RootState) => state.units.list.createUnitDialog.model.materialModel
export const selectProjectModel = (state: RootState) => state.units.list.createUnitDialog.model.projectModel
export const selectPgModel = (state: RootState) => state.units.list.createUnitDialog.model.productGroupModel
export const selectPkModel = (state: RootState) => state.units.list.createUnitDialog.model.productKeyModel

export const selectUnitValidation = (state: RootState) => state.units.list.createUnitDialog.validationData.unitValidation
export const selectEtoUnitValidation = (state: RootState) => state.units.list.createUnitDialog.validationData.etoUnitValidation

export default slice.reducer
