import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@mui/material'
import {
  type FC,
  useState,
} from 'react'

import {
  useAppDispatch,
  useAppSelector,
} from 'app/store/hooks'

interface Props {
  text?: string
  setText: (text: string | undefined) => void
}

export const TextDialog: FC<Props> = ({
  text,
  setText,
}) => {

  const dispatch = useAppDispatch()

  const handleCloseButton = () => {
  }

  const handleSave = () => {
  }

  return (
    <Dialog
      open={text !== undefined}
      onClose={handleSave}
      scroll="body"
      maxWidth="lg"
      sx={{ '& .MuiDialog-paper': { borderRadius: '12px' } }
      }
    >
      <DialogContent>
        <Box sx={
          { background: 'white' }}
        >
          {text}
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button
          onClick={() => {
            setText(undefined)
          }}
          data-testid="text-button-close"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
