import ReplayIcon from '@mui/icons-material/Replay'
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider, Stack, IconButton, CardActions,
} from '@mui/material'
import { type FunctionComponent, useState } from 'react'

import { useAppDispatch, useAppSelector } from 'app/store'

import {
  selectSync, syncUnitData, requeueAll, checkData, checkUnitsData, processing as processingAction, setChecks,
  loadChecks,
  requeueEntities, checkSubOemsData, checkUnitReferencesData, checkRplsData, checkIdenticalsData,
  checkEtosData, checkEquipmentReferencesData, checkEquipmentsData, checkDesignsData, checkAnalogsData,
} from '../../../app/store/administration/syncSlice'
import { ButtonMenu } from '../../units/Menu'
import { ConfirmationDialog } from '../ConfirmationDialog'
import { ScheduleDialog } from '../ScheduleDialog'

import SyncList from './SyncList'

const SyncCard: FunctionComponent = () => {
  const syncState = useAppSelector(selectSync)
  const {
    status,
    processing,
    checks,
  } = syncState
  const dispatch = useAppDispatch()
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [scheduleOpen, setScheduleOpen] = useState(false)

  const renderButtons = () => {
    return (
      <Stack
        spacing={1}
        direction={'row'}
      >
        {status === 'idle' &&
          <>
            <Button
              disabled={processing}
              variant="outlined"
              color="primary"
              onClick={() => {
                dispatch(requeueAll())
                  .then(() => dispatch(processingAction()))
              }}
            >
              Resolve all
            </Button>
            <ButtonMenu buttonTitle={'Checks'}>
              <Button
                disabled={processing}
                variant="outlined"
                color="primary"
                onClick={() => {
                  dispatch(checkData())
                    .then(() => {
                      dispatch(setChecks(undefined))
                      dispatch(processingAction())
                    })
                }}
              >
                Check all
              </Button>
              <Button
                disabled={processing}
                variant="outlined"
                color="primary"
                onClick={() => {
                  dispatch(checkAnalogsData())
                    .then(() => {
                      dispatch(setChecks(undefined))
                      dispatch(processingAction())
                    })
                }}
              >
                Check analogs
              </Button>
              <Button
                disabled={processing}
                variant="outlined"
                color="primary"
                onClick={() => {
                  dispatch(checkDesignsData())
                    .then(() => {
                      dispatch(setChecks(undefined))
                      dispatch(processingAction())
                    })
                }}
              >
                Check designs
              </Button>
              <Button
                disabled={processing}
                variant="outlined"
                color="primary"
                onClick={() => {
                  dispatch(checkEquipmentsData())
                    .then(() => {
                      dispatch(setChecks(undefined))
                      dispatch(processingAction())
                    })
                }}
              >
                Check equipments
              </Button>
              <Button
                disabled={processing}
                variant="outlined"
                color="primary"
                onClick={() => {
                  dispatch(checkEquipmentReferencesData())
                    .then(() => {
                      dispatch(setChecks(undefined))
                      dispatch(processingAction())
                    })
                }}
              >
                Check equipments references
              </Button>
              <Button
                disabled={processing}
                variant="outlined"
                color="primary"
                onClick={() => {
                  dispatch(checkEtosData())
                    .then(() => {
                      dispatch(setChecks(undefined))
                      dispatch(processingAction())
                    })
                }}
              >
                Check etos
              </Button>
              <Button
                disabled={processing}
                variant="outlined"
                color="primary"
                onClick={() => {
                  dispatch(checkIdenticalsData())
                    .then(() => {
                      dispatch(setChecks(undefined))
                      dispatch(processingAction())
                    })
                }}
              >
                Check identicals
              </Button>
              <Button
                disabled={processing}
                variant="outlined"
                color="primary"
                onClick={() => {
                  dispatch(checkRplsData())
                    .then(() => {
                      dispatch(setChecks(undefined))
                      dispatch(processingAction())
                    })
                }}
              >
                Check rpls
              </Button>
              <Button
                disabled={processing}
                variant="outlined"
                color="primary"
                onClick={() => {
                  dispatch(checkSubOemsData())
                    .then(() => {
                      dispatch(setChecks(undefined))
                      dispatch(processingAction())
                    })
                }}
              >
                Check suboems
              </Button>
              <Button
                disabled={processing}
                variant="outlined"
                color="primary"
                onClick={() => {
                  dispatch(checkUnitReferencesData())
                    .then(() => {
                      dispatch(setChecks(undefined))
                      dispatch(processingAction())
                    })
                }}
              >
                Check units references
              </Button>
              <Button
                disabled={processing}
                variant="outlined"
                color="primary"
                onClick={() => {
                  dispatch(checkUnitsData())
                    .then(() => {
                      dispatch(setChecks(undefined))
                      dispatch(processingAction())
                    })
                }}
              >
                Check units
              </Button>
            </ButtonMenu>

          </>}
        <IconButton onClick={() => {
          dispatch(setChecks(undefined))
          dispatch(loadChecks())
          dispatch(processingAction())
        }}
        >
          <ReplayIcon/>
        </IconButton>
      </Stack>
    )
  }

  return (
    <>
      <Card sx={{ height: '100%' }}>
        <CardHeader
          title="Actions"
          action={renderButtons()}
        />
        <Divider/>
        <CardContent>
          <SyncList/>
        </CardContent>
        <CardActions>
          <Button
            disabled={processing || (!checks?.some(c => c.checked) ?? true)}
            variant="outlined"
            color="primary"
            onClick={() => {
              if (checks) {
                const filtered = checks.filter(c => c.checked).map(c => c.entityId)
                dispatch(requeueEntities(Array.from(new Set(filtered))))
                  .then(() => {
                    dispatch(setChecks(undefined))
                    dispatch(loadChecks())
                    dispatch(processingAction())
                  })
              }
            }
            }
          >
            Try repair
          </Button>
        </CardActions>
      </Card>
      <ConfirmationDialog
        open={confirmOpen}
        onConfirm={() => {
          dispatch(syncUnitData())
            .then(() => dispatch(processingAction()))
        }}
        setOpen={setConfirmOpen}
      />
      <ScheduleDialog
        open={scheduleOpen}
        setOpen={setScheduleOpen}
      />

    </>
  )
}

export default SyncCard
