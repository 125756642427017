import { SyncCheckDto } from '../model/contracts/SyncCheckDto'

import { API_GATEWAY_ADDRESS } from './api'
import authService from './AuthService'
import { handleErrors } from './errorHandler'
import { msalFetch } from './graph'

const SYNC_ULR = `${API_GATEWAY_ADDRESS}/bs/sync`
const CHECK_ULR = `${API_GATEWAY_ADDRESS}/bs/check`
const JOBS_ULR = `${API_GATEWAY_ADDRESS}/bs/jobs`

export class SyncApi {
  async syncData () {
    const token = await authService.getIdentity()

    await msalFetch()
      .post(SYNC_ULR, token.accessToken)
      .then(handleErrors)
  }

  async scheduleSyncData (enqueueAt: string) {
    const token = await authService.getIdentity()

    await msalFetch()
      .post(`${SYNC_ULR}/schedule`, token.accessToken, enqueueAt)
      .then(handleErrors)
  }

  async getChecks () {
    const token = await authService.getIdentity()

    return await msalFetch()
      .get(CHECK_ULR, token.accessToken)
      .then(handleErrors)
      .then<SyncCheckDto[]>(async (res) => await res.json())
  }

  async checkData () {
    const token = await authService.getIdentity()

    await msalFetch()
      .post(`${CHECK_ULR}`, token.accessToken)
      .then(handleErrors)
  }

  async checkAnalogsData () {
    const token = await authService.getIdentity()

    await msalFetch()
      .post(`${CHECK_ULR}/analogs`, token.accessToken)
      .then(handleErrors)
  }

  async checkDesignsData () {
    const token = await authService.getIdentity()

    await msalFetch()
      .post(`${CHECK_ULR}/designs`, token.accessToken)
      .then(handleErrors)
  }

  async checkEquipmentsData () {
    const token = await authService.getIdentity()

    await msalFetch()
      .post(`${CHECK_ULR}/equipments`, token.accessToken)
      .then(handleErrors)
  }

  async checkEquipmentReferencesData () {
    const token = await authService.getIdentity()

    await msalFetch()
      .post(`${CHECK_ULR}/equipment-references`, token.accessToken)
      .then(handleErrors)
  }

  async checkEtosData () {
    const token = await authService.getIdentity()

    await msalFetch()
      .post(`${CHECK_ULR}/etos`, token.accessToken)
      .then(handleErrors)
  }

  async checkIdenticalsData () {
    const token = await authService.getIdentity()

    await msalFetch()
      .post(`${CHECK_ULR}/identicals`, token.accessToken)
      .then(handleErrors)
  }

  async checkRplsData () {
    const token = await authService.getIdentity()

    await msalFetch()
      .post(`${CHECK_ULR}/rpls`, token.accessToken)
      .then(handleErrors)
  }

  async checkSubOemsData () {
    const token = await authService.getIdentity()

    await msalFetch()
      .post(`${CHECK_ULR}/suboems`, token.accessToken)
      .then(handleErrors)
  }

  async checkUnitReferencesData () {
    const token = await authService.getIdentity()

    await msalFetch()
      .post(`${CHECK_ULR}/unit-references`, token.accessToken)
      .then(handleErrors)
  }

  async checkUnitsData () {
    const token = await authService.getIdentity()

    await msalFetch()
      .post(`${CHECK_ULR}/units`, token.accessToken)
      .then(handleErrors)
  }

  async getCheckData (checkId: number) {
    const token = await authService.getIdentity()

    return await msalFetch()
      .get(`${CHECK_ULR}/${checkId}/data`, token.accessToken)
      .then<string>((res) => res.text())
  }

  async getCheckMessage (checkId: number) {
    const token = await authService.getIdentity()

    return await msalFetch()
      .get(`${CHECK_ULR}/${checkId}/message`, token.accessToken)
      .then<string>((res) => res.text())
  }

  async requeueAll () {
    const token = await authService.getIdentity()

    await msalFetch()
      .post(`${JOBS_ULR}/requeue/all`, token.accessToken)
      .then(handleErrors)
  }

  async requeueEntities (ids: number[]) {
    const token = await authService.getIdentity()

    await msalFetch()
      .post(`${JOBS_ULR}/requeue`, token.accessToken, ids)
      .then(handleErrors)
  }

  async processing () {
    const token = await authService.getIdentity()

    return await msalFetch()
      .get(`${JOBS_ULR}/processing`, token.accessToken)
      .then(handleErrors)
      .then<boolean>(async (res) => await res.json())
  }
}

const syncApi = new SyncApi()

export default syncApi
