import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import { IconButton } from '@mui/material'
import { type XHRUploadOptions } from '@uppy/xhr-upload'
import {
  type FC,
  useState,
} from 'react'

import { type UnitDto } from 'shared/model/contracts/UnitDto'

import { API_GATEWAY_ADDRESS } from '../../../../../shared/api/api'

import { DropzoneUnitPictures } from './DropzoneUnitPictures'

interface UploadSchemeProps {
  unitId: UnitDto['unitFileId']
  isDisabled?: boolean
}

export const UploadScheme: FC<UploadSchemeProps> = ({
  unitId,
  isDisabled,
}) => {
  const [isOpenDropzone, setIsOpenDropzone] = useState(false)

  const openDragNDrop = () => {
    setIsOpenDropzone(true)
  }

  const logisticsApi = `${API_GATEWAY_ADDRESS}/el`

  const xhrInOptions: XHRUploadOptions = {
    endpoint: `${logisticsApi}/units/${unitId}/schemes`,
    method: 'POST',
  }

  return (
    <>
      <IconButton
        onClick={openDragNDrop}
        disabled={isDisabled}
      >
        <CloudUploadOutlinedIcon/>
      </IconButton>
      <DropzoneUnitPictures
        dropzoneId={`${unitId}-schemes`}
        XHRInOptions={xhrInOptions}
        isOpen={isOpenDropzone}
        setIsOpen={setIsOpenDropzone}
        invalidateData={() => {
        }}
      />
    </>
  )
}
