import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import ImportExportOutlinedIcon from '@mui/icons-material/ImportExportOutlined'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Stack,
  TextField, Divider,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import * as fuzzysort from 'fuzzysort'
import React, {
  type ChangeEvent,
  type FC,
  type ReactNode,
  useEffect,
  useState,
} from 'react'
import { useDebounce } from 'usehooks-ts'

import { ecTheme } from 'app/config/theme/ecTheme'

import { type CatalogRecord } from 'entities/logistics/Catalog'

import { themeSpacingFromPixels } from '../../../lib/workWithThemeSpacing'

export interface SelectableItem {
  isSelected: boolean
  record: CatalogRecord
}

interface SelectMultipleItemsProps {
  title?: string
  showId?: boolean
  selectedValues: CatalogRecord[] | undefined
  catalogValues: CatalogRecord[] | undefined
  onSaveAction: (data: CatalogRecord[] | undefined) => void
  forecastValues?: CatalogRecord[]
  checkIds?: boolean
  onForecastAction?: () => void
}

interface HighlightedCatalogRecord {
  id: number
  value: string | ReactNode
}

interface SelectableHighlightedItem {
  isSelected: boolean
  record: HighlightedCatalogRecord
}

export const SelectMultipleItems: FC<SelectMultipleItemsProps> = (
  {
    forecastValues,
    catalogValues,
    selectedValues,
    onSaveAction,
    onForecastAction,
    title,
    checkIds,
  }) => {

  const [dialogItems, setDialogItems] = useState<SelectableItem[] | SelectableHighlightedItem[] | undefined>(undefined)
  const [forecastItems, setForecastItems] = useState<SelectableItem[] | undefined>(undefined)
  const [isOpen, setIsOpen] = useState(true)
  const [fullDialogValuesList, setFullDialogValuesList] = useState<SelectableItem[] | undefined>(undefined)

  const [searchString, setSearchString] = useState<string>('')
  const debouncedSearchString = useDebounce<string>(searchString, 500)

  useEffect(() => {
    startFilter(debouncedSearchString)
  }, [debouncedSearchString, fullDialogValuesList])

  const startFilter = (filterString: string): void => {
    if (!filterString) {
      setDialogItems(fullDialogValuesList)
      return
    }

    const foundValues: Fuzzysort.KeyResults<SelectableItem> = fuzzysort.go<SelectableItem>(
      filterString,
      fullDialogValuesList ?? [],
      { key: 'record.value' },
    )

    const filteredValues = foundValues.map(curreV => {
      const highlightValue = fuzzysort.highlight(curreV, (match, index) => {
        return (<Typography
          key={index}
          component="span"
          display="inline"
          sx={{ 'color': ecTheme.palette.error.main }}
        >{match}</Typography>)
      })

      return {
        isSelected: curreV.obj.isSelected,
        record: {
          id: curreV.obj.record.id,
          value: highlightValue || '',
        },
      }
    })
    // @ts-ignore
    setDialogItems(filteredValues)
  }

  function getPropsStateSelectedRecords () {
    return catalogValues?.map((loadedRecord) => {
      const elementChecked = selectedValues?.find(item => item.id === loadedRecord.id)
      if (elementChecked) {
        return {
          isSelected: true,
          record: loadedRecord,
        }
      } else {
        return {
          isSelected: false,
          record: loadedRecord,
        }
      }
    })
  }

  function getPropsStateSelectedForecastRecords () {
    if (checkIds) {

      return forecastValues?.map((item) => {

        const dialogItem = dialogItems?.find(dialogItem => dialogItem.record.id === item.id)

        return {
          isSelected: dialogItem?.isSelected,
          record: dialogItem?.record,
        } as SelectableItem
      })
    }
    return forecastValues?.map((item) => {
      return {
        isSelected: false,
        record: item,
      } as SelectableItem
    })
  }

  useEffect(() => {
    const currentStateSelectionRecords: SelectableItem[] | undefined = getPropsStateSelectedForecastRecords()
    setForecastItems(currentStateSelectionRecords ?? [])
  }, [forecastValues])

  useEffect(() => {
    const currentStateSelectionRecords: SelectableItem[] | undefined = getPropsStateSelectedRecords()
    setDialogItems(currentStateSelectionRecords ?? [])
    setFullDialogValuesList(currentStateSelectionRecords ?? [])
  }, [])

  const handleSearchStringChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value)
  }

  const onChangSelection = (id: number): void => {
    setFullDialogValuesList(prevState => prevState?.map(
      (item: SelectableItem): SelectableItem => {
        if (item.record.id === id) {
          item.isSelected = !item.isSelected
          return item
        }
        return item
      },
    ))
    if (checkIds) {
      const currentStateSelectionRecords = getPropsStateSelectedForecastRecords()
      setForecastItems(currentStateSelectionRecords ?? [])
    } else {
      const forecastItemsSelected = forecastItems?.map((item) => {
        if (item.record.id === id) {
          item.isSelected = !item.isSelected
          return item
        } else {
          return item
        }
      })
      setForecastItems(forecastItemsSelected ?? undefined)
    }
  }

  function getSelectedRecords () {
    if (checkIds) {
      return fullDialogValuesList
        ?.filter((item) => item.isSelected)
        .map((selectedItem) => selectedItem.record)
    }
    const catalog = fullDialogValuesList
      ?.filter((item) => item.isSelected)
      .map((selectedItem) => selectedItem.record) ?? []
    const forecast = forecastItems?.filter((item) => item.isSelected)
      .map((selectedItem) => selectedItem.record) ?? []

    return forecast.concat(catalog)
  }

  const handleSave = () => {
    onSaveAction(getSelectedRecords())
    setIsOpen(false)
  }

  const handleForecast = () => {
    if (onForecastAction) {
      onForecastAction()
    }
  }

  const handleCancel = (): void => {
    onSaveAction(
      getPropsStateSelectedRecords()?.filter((v: SelectableItem) => v.isSelected)?.map((v: SelectableItem) => v.record))
    setIsOpen(false)
  }

  return (
    <Dialog
      scroll={'paper'}
      open={isOpen}
      onClose={() => {
        onSaveAction(
          getPropsStateSelectedRecords()?.filter((v: SelectableItem) => v.isSelected)
            ?.map((v: SelectableItem) => v.record))
        setIsOpen(false)
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          label="Search"
          value={searchString}
          onChange={handleSearchStringChange}
          autoFocus={true}
          sx={{ marginTop: themeSpacingFromPixels(8) }}
        />
        <FormControl>
          {forecastValues && forecastValues.length > 0 && searchString.length === 0 && <>
            {forecastItems?.map((item) => {
              return (
                <FormControlLabel
                  // key={item.record.id}
                  control={<Checkbox
                    checked={item.isSelected}
                    onChange={() => {
                      onChangSelection(item.record.id)
                    }}

                  />}
                  label={item.record.value}
                />
              )
            },
            )}
            <Divider/>
          </>}
          {dialogItems?.map((item: SelectableHighlightedItem) => {
            return (
              <FormControlLabel
                key={item.record.id}
                control={<Checkbox
                  checked={item.isSelected}
                  onChange={() => {
                    onChangSelection(item.record.id)
                  }}

                />}
                label={item.record.value}
              />

            )
          },
          )}
        </FormControl>
      </DialogContent>
      <DialogActions>

        <Stack
          direction="row"
          justifyContent="space-evenly"
          sx={{ flex: 'auto' }}
        >
          <Button
            startIcon={<ClearOutlinedIcon/>}
            size="small"
            variant="outlined"
            onClick={handleCancel}
          >
            <Typography variant="body1">Cancel</Typography>
          </Button>
          <Button
            startIcon={<SaveOutlinedIcon/>}
            size="small"
            variant="contained"
            onClick={handleSave}
          >
            <Typography variant="body1">Save</Typography>
          </Button>
          {searchString.length === 0 && <Button
            startIcon={<ImportExportOutlinedIcon/>}
            size="small"
            variant="contained"
            onClick={handleForecast}
          >
            <Typography variant="body1">Forecast</Typography>
          </Button>}
        </Stack>
      </DialogActions>
    </Dialog>
  )
}

SelectMultipleItems.defaultProps = {
  title: 'Chose items',
  showId: false,
}
