export function isEmptyOrSpaces (str: string | undefined | null) {
  return !str || str.match(/^[\s\t]*$/) !== null
}

export function replaceInvalidPathChars (str: string | undefined | null, char = '_') {
  return !str || str.replace(/[/\\?%*:|"<>]/g, char)
}

export function countArticles (str: string | undefined | null): number {
  if (str) {
    const regex = /[^\s;:,.][0-9A-Za-zА-Яа-я%_\-\\<\\>]*|[0-9A-Za-zА-Яа-я%_\-\\<\\>][^\s;:,.]/gm
    const matchAll = str.match(regex)

    if (matchAll === null) {
      return 0
    }

    const count = Array.from(matchAll)?.length
    return count || 0
  }
  return 0
}

export function countMfgArticles (str: string | undefined | null): number {
  if (str) {
    const regex = /[^;][\s0-9A-Za-zА-Яа-я_%^\-+=<>.,:*"'&?$#/@`~{}\\[\]()]*|[^;]/gm
    const matchAll = str.match(regex)

    if (matchAll === null) {
      return 0
    }

    const count = Array.from(matchAll)?.length
    return count || 0
  }
  return 0
}

export function getFileNameExtension (fileName: string): string | undefined {
  const words = fileName.split('.')
  if (words?.length > 1) {
    const ext = words?.pop()
    if (ext) {
      return ext
    }
    return undefined
  }

  return undefined
}

export function cutValue (value: string, length: number): string {
  if (value.length > length) {
    return value.slice(0, length).concat('...')
  }
  return value
}

export const isValueLongerThenLength = (value: string, length: number): boolean => {
  if (!value) {
    return false
  }

  return value.length > length
}
