import {
  createAsyncThunk,
  createSlice,
  type PayloadAction,
  type SerializedError,
} from '@reduxjs/toolkit'

import syncApi from '../../../shared/api/SyncApi'
import { SyncCheckDto } from '../../../shared/model/contracts/SyncCheckDto'
import { type RootState } from '../store'

export interface SyncState {
  status: 'idle' | 'loading' | 'failed'
  processing: boolean
  checks?: SyncCheckDto[]
  pageError?: SerializedError
  actionError?: SerializedError
  openEditSettingsDialog: boolean
}

const initialState: SyncState = {
  status: 'loading',
  processing: false,
  openEditSettingsDialog: false,
}

export const loadChecks = createAsyncThunk('checks/load', async () => {
  return await syncApi.getChecks()
})

export const loadCheckData = createAsyncThunk('checks/load-data', async (checkId: number) => {
  return await syncApi.getCheckData(checkId)
})

export const loadCheckMessage = createAsyncThunk('checks/load-message', async (checkId: number) => {
  return await syncApi.getCheckMessage(checkId)
})

export const syncUnitData = createAsyncThunk('unitData/sync', async () => {
  await syncApi.syncData()
})

export const scheduleSyncUnitData = createAsyncThunk('unitData/schedule', async (enqueueAt: string) => {
  await syncApi.scheduleSyncData(enqueueAt)
})

export const checkData = createAsyncThunk('unitData/check', async () => {
  await syncApi.checkData()
})

export const requeueAll = createAsyncThunk('checks/requeueAll', async () => {
  await syncApi.requeueAll()
})

export const requeueEntities = createAsyncThunk('checks/requeueEntities', async (ids: number[]) => {
  await syncApi.requeueEntities(ids)
})

export const processing = createAsyncThunk('unitData/processing', async () => {
  return await syncApi.processing()
})

export const checkAnalogsData = createAsyncThunk('unitData/check-analogs', async () => {
  await syncApi.checkAnalogsData()
})

export const checkDesignsData = createAsyncThunk('unitData/check-designs', async () => {
  await syncApi.checkDesignsData()
})

export const checkEquipmentReferencesData = createAsyncThunk('unitData/check-equipments-references', async () => {
  await syncApi.checkEquipmentReferencesData()
})

export const checkEtosData = createAsyncThunk('unitData/check-etos', async () => {
  await syncApi.checkEtosData()
})

export const checkEquipmentsData = createAsyncThunk('unitData/check-equipments', async () => {
  await syncApi.checkEquipmentsData()
})

export const checkIdenticalsData = createAsyncThunk('unitData/check-identicals', async () => {
  await syncApi.checkIdenticalsData()
})

export const checkRplsData = createAsyncThunk('unitData/check-rpls', async () => {
  await syncApi.checkRplsData()
})

export const checkSubOemsData = createAsyncThunk('unitData/check-suboems', async () => {
  await syncApi.checkSubOemsData()
})

export const checkUnitReferencesData = createAsyncThunk('unitData/check-unit-references', async () => {
  await syncApi.checkUnitReferencesData()
})

export const checkUnitsData = createAsyncThunk('unitData/check-units', async () => {
  await syncApi.checkUnitsData()
})

export const syncSlice = createSlice({
  name: 'sync',
  initialState,
  reducers: {
    showActionError: (state, action: PayloadAction<Error>) => {
      state.actionError = action.payload
    },
    hideActionError: (state) => {
      state.actionError = undefined
    },
    setChecks: (state, action: PayloadAction<SyncCheckDto[] | undefined>) => {
      if (action.payload) {
        state.checks = action.payload
      } else {
        state.checks = undefined
      }
    },
    checkCheck: (state, action: PayloadAction<number>) => {
      if (state.checks) {
        const check = state.checks.filter(c => c.id === action.payload)[0]
        if (check) {
          if (check.checked) {
            check.checked = false
          } else {
            check.checked = true
          }
        }
      }
    },
    unCheckCheck: (state, action: PayloadAction<number>) => {
      if (state.checks) {
        const checks = state.checks.filter(c => c.entityId === action.payload)

        checks.forEach(c => {
          c.checked = false
        })
      }
    },
    checkChecks: (state, action: PayloadAction<string>) => {
      if (state.checks) {
        const checks = state.checks.filter(c => c.type === action.payload)

        if (checks[0].checked) {
          checks.forEach(c => {
            c.checked = false
          })
        } else {
          checks.forEach(c => {
            c.checked = true
          })
        }
      }
    },
    unCheckChecks: (state, action: PayloadAction<string>) => {
      if (state.checks) {
        const checks = state.checks.filter(c => c.type === action.payload)

        checks.forEach(c => {
          c.checked = false
        })
      }
    },
    setActionError: (state, action: PayloadAction<Error>) => {
      state.actionError = action.payload
    },
    clearActionError: (state) => {
      state.actionError = undefined
    },
  },
  extraReducers: (build) => {
    build.addCase(loadChecks.pending, (state, action) => {
      state.status = 'loading'
    })

    build.addCase(loadChecks.fulfilled, (state, action) => {
      state.status = 'idle'
      state.checks = action.payload.map(c => {
        return {
          id: c.id,
          type: c.type,
          data: c.data,
          subMessage: c.subMessage,
          entityId: c.entityId,
          isBridge: c.isBridge,
          resolved: c.resolved,
          checked: false,
        }
      })
    })

    build.addCase(loadChecks.rejected, (state, action) => {
      state.status = 'failed'
      state.pageError = action.error
    })

    build.addCase(syncUnitData.pending, (state, action) => {
      state.status = 'loading'
    })

    build.addCase(syncUnitData.fulfilled, (state, action) => {
      state.status = 'idle'
    })

    build.addCase(syncUnitData.rejected, (state, action) => {
      state.status = 'failed'
      state.actionError = action.error
    })

    build.addCase(scheduleSyncUnitData.pending, (state, action) => {
      state.status = 'loading'
    })

    build.addCase(scheduleSyncUnitData.fulfilled, (state, action) => {
      state.status = 'idle'
    })

    build.addCase(scheduleSyncUnitData.rejected, (state, action) => {
      state.status = 'failed'
      state.actionError = action.error
    })

    build.addCase(checkData.pending, (state, action) => {
      state.status = 'loading'
    })

    build.addCase(checkData.fulfilled, (state, action) => {
      state.status = 'idle'
    })

    build.addCase(checkData.rejected, (state, action) => {
      state.status = 'failed'
      state.actionError = action.error
    })

    build.addCase(requeueAll.pending, (state, action) => {
      state.status = 'loading'
    })

    build.addCase(requeueAll.fulfilled, (state, action) => {
      state.status = 'idle'
    })

    build.addCase(requeueAll.rejected, (state, action) => {
      state.status = 'failed'
      state.actionError = action.error
    })

    build.addCase(processing.pending, (state, action) => {
      state.status = 'loading'
    })

    build.addCase(processing.fulfilled, (state, action) => {
      state.processing = action.payload
      state.status = 'idle'
    })

    build.addCase(processing.rejected, (state, action) => {
      state.status = 'failed'
      state.actionError = action.error
    })

    build.addCase(checkAnalogsData.pending, (state, action) => {
      state.status = 'loading'
    })

    build.addCase(checkAnalogsData.fulfilled, (state, action) => {
      state.status = 'idle'
    })

    build.addCase(checkAnalogsData.rejected, (state, action) => {
      state.status = 'failed'
      state.actionError = action.error
    })

    build.addCase(checkDesignsData.pending, (state, action) => {
      state.status = 'loading'
    })

    build.addCase(checkDesignsData.fulfilled, (state, action) => {
      state.status = 'idle'
    })

    build.addCase(checkDesignsData.rejected, (state, action) => {
      state.status = 'failed'
      state.actionError = action.error
    })

    build.addCase(checkEquipmentsData.pending, (state, action) => {
      state.status = 'loading'
    })

    build.addCase(checkEquipmentsData.fulfilled, (state, action) => {
      state.status = 'idle'
    })

    build.addCase(checkEquipmentsData.rejected, (state, action) => {
      state.status = 'failed'
      state.actionError = action.error
    })

    build.addCase(checkEquipmentReferencesData.pending, (state, action) => {
      state.status = 'loading'
    })

    build.addCase(checkEquipmentReferencesData.fulfilled, (state, action) => {
      state.status = 'idle'
    })

    build.addCase(checkEquipmentReferencesData.rejected, (state, action) => {
      state.status = 'failed'
      state.actionError = action.error
    })

    build.addCase(checkEtosData.pending, (state, action) => {
      state.status = 'loading'
    })

    build.addCase(checkEtosData.fulfilled, (state, action) => {
      state.status = 'idle'
    })

    build.addCase(checkEtosData.rejected, (state, action) => {
      state.status = 'failed'
      state.actionError = action.error
    })

    build.addCase(checkIdenticalsData.pending, (state, action) => {
      state.status = 'loading'
    })

    build.addCase(checkIdenticalsData.fulfilled, (state, action) => {
      state.status = 'idle'
    })

    build.addCase(checkIdenticalsData.rejected, (state, action) => {
      state.status = 'failed'
      state.actionError = action.error
    })

    build.addCase(checkRplsData.pending, (state, action) => {
      state.status = 'loading'
    })

    build.addCase(checkRplsData.fulfilled, (state, action) => {
      state.status = 'idle'
    })

    build.addCase(checkRplsData.rejected, (state, action) => {
      state.status = 'failed'
      state.actionError = action.error
    })

    build.addCase(checkSubOemsData.pending, (state, action) => {
      state.status = 'loading'
    })

    build.addCase(checkSubOemsData.fulfilled, (state, action) => {
      state.status = 'idle'
    })

    build.addCase(checkSubOemsData.rejected, (state, action) => {
      state.status = 'failed'
      state.actionError = action.error
    })

    build.addCase(checkUnitReferencesData.pending, (state, action) => {
      state.status = 'loading'
    })

    build.addCase(checkUnitReferencesData.fulfilled, (state, action) => {
      state.status = 'idle'
    })

    build.addCase(checkUnitReferencesData.rejected, (state, action) => {
      state.status = 'failed'
      state.actionError = action.error
    })

    build.addCase(checkUnitsData.pending, (state, action) => {
      state.status = 'loading'
    })

    build.addCase(checkUnitsData.fulfilled, (state, action) => {
      state.status = 'idle'
    })

    build.addCase(checkUnitsData.rejected, (state, action) => {
      state.status = 'failed'
      state.actionError = action.error
    })
  },
})

export const {
  showActionError,
  hideActionError,
  setChecks,
  setActionError,
  clearActionError,
  checkCheck,
  unCheckCheck,
  checkChecks,
  unCheckChecks,
} = syncSlice.actions

export const selectSync = (state: RootState) => state.administration.sync

export default syncSlice.reducer
