import {
  createAsyncThunk,
  createSlice,
  type SerializedError,
} from '@reduxjs/toolkit'

import unitsApi from 'shared/api/UnitsApi'

import { ReportDto } from '../../../shared/model/contracts/ReportDto'
import { type RootState } from '../store'

import { UnitFilter } from './unitsFiltersSlice'

export interface UnitsReportState {
  status: 'idle' | 'loading' | 'failed'
  blob?: string
  reports?: ReportDto[]
  error?: SerializedError
}

const initialState: UnitsReportState = { status: 'idle' }

export const getReport = createAsyncThunk(
  'units-report/download',
  async (request: { type: string, filter?: string, filters?: UnitFilter[], startDate?: string, endDate?: string }) => {
    return await unitsApi
      .downloadReport(request.filter ?? '', request.type, request.filters, request.startDate, request.endDate)
      .then((blob) => blob && window.URL.createObjectURL(blob))
  },
)

export const getReports = createAsyncThunk(
  'units-report/getList',
  async () => {
    return await unitsApi.getReports()
  },
)

export const unitsReportSlice = createSlice({
  name: 'units-report',
  initialState,
  reducers: {
    clearReportBlob: (state) => {
      state.blob = undefined
    },
  },
  extraReducers: (build) => {
    build.addCase(getReports.pending, (state) => {
      state.status = 'loading'
    })

    build.addCase(getReports.fulfilled, (state, action) => {
      state.status = 'idle'
      state.reports = action.payload
    })

    build.addCase(getReports.rejected, (state, action) => {
      state.status = 'failed'
      state.error = action.error
    })
  },
})
export const selectUnitsReport = (state: RootState) => state.units.list.report

export const {
  clearReportBlob,
} = unitsReportSlice.actions

export default unitsReportSlice.reducer
