// @ts-ignore
import PhotoSwipeLightbox from 'photoswipe/lightbox'
// eslint-disable-next-line import/order
import {
  type FC,
  useEffect,
  useState,
} from 'react'

import 'photoswipe/style.css'
import wretch from 'wretch'

import authService from 'shared/api/AuthService'

import { API_GATEWAY_ADDRESS } from '../../../api/api'
import { TechnicalGalleryImage } from '../model/types'

// eslint-disable-next-line import/order
import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from '@mui/material'

// eslint-disable-next-line import/order

// eslint-disable-next-line import/order

interface GalleryProps {
  galleryID: string
  images: TechnicalGalleryImage[]
  preload?: boolean
  imageBarIconButtonAction?: React.ReactNode[]
}

const createImage = async (longUrl: string) => {

  const fullApiUrl = `${API_GATEWAY_ADDRESS}/el/units`

  const token = await authService.getIdentity()
  const apiWretch = wretch(fullApiUrl)
    .auth(`Bearer ${token.accessToken}`)

  const data = await apiWretch
    .get(longUrl)
    .blob()
  const url = URL.createObjectURL(data)
  const img = new Image()
  img.src = url

  return await img
}

export const Gallery: FC<GalleryProps> = ({
  galleryID,
  images,
  preload,
}) => {

  const [imgSet, setImgSet] = useState<TechnicalGalleryImage[]>([])

  useEffect(() => {
    const getData = async () => {
      const t = await Promise.all(
        images.map(async (image) => createImage(image.largeURL)))

      const imageList = t.map((imageObj: HTMLImageElement, index): TechnicalGalleryImage => {
        return {
          largeURL: imageObj.src,
          width: imageObj.width,
          height: imageObj.height,
          fileName: images[index].fileName,
        }
      })

      setImgSet(imageList)
    }

    if (preload) {
      getData()
    } else {
      setImgSet(images)
    }
  }, [images])

  useEffect(() => {
    let lightbox: PhotoSwipeLightbox | null = new PhotoSwipeLightbox({
      gallery: `#${galleryID}`,
      pswpModule: () => import('photoswipe'),
      childSelector: '.pswp-photo',
      // initialZoomLevel:'fit',
    })
    lightbox?.init()

    return () => {
      lightbox?.destroy()
      lightbox = null
    }
  }, [])

  return (
    <ImageList
      id={galleryID}
    >
      {
        imgSet.map((image: TechnicalGalleryImage, index) => (
          <ImageListItem sx={{ border: '1px solid' }}>
            <a
              className={'pswp-photo'}
              href={image.largeURL}
              data-pswp-width={image.width || window.visualViewport.width}
              data-pswp-height={image.height || window.visualViewport.height}
              key={galleryID + '-' + index}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={image.thumbnailURL || image.largeURL}
                width={'400px'}
                alt=""
                loading={'lazy'}
              />

            </a>
            <ImageListItemBar
              title={image?.fileName}
            />
          </ImageListItem>
        ))
      }
    </ImageList>
  )
}
