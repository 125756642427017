import ReplayIcon from '@mui/icons-material/Replay'
import {
  Card,
  CardContent,
  CardHeader,
  Divider, Stack, IconButton,
} from '@mui/material'
import { type FunctionComponent, useState } from 'react'

import { useAppDispatch, useAppSelector } from 'app/store'

import { loadBrokers, setBrokers, selectBrokers } from '../../../app/store/administration/brokerSlice'

import BrokersList from './BrokersList'

const BrokersCard: FunctionComponent = () => {
  const brokersState = useAppSelector(selectBrokers)
  const {
    status,
  } = brokersState
  const dispatch = useAppDispatch()

  const renderButtons = () => {
    return (
      status === 'idle' && (
        <Stack
          spacing={1}
          direction={'row'}
        >
          <IconButton onClick={() => {
            dispatch(setBrokers(undefined))
            dispatch(loadBrokers())
          }}
          >
            <ReplayIcon/>
          </IconButton>
        </Stack>
      )
    )
  }

  return (
    <>
      <Card sx={{ height: '100%' }}>
        <CardHeader
          title="Brokers"
          action={renderButtons()}
        />
        <Divider/>
        <CardContent>
          <BrokersList/>
        </CardContent>
      </Card>
    </>
  )
}

export default BrokersCard
