import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import { type FC, type ReactNode, useCallback, useEffect, useState } from 'react'

import { baseTheme } from 'app/config/theme/baseTheme'
import { logisticsTheme } from 'app/config/theme/logisticsTheme'
import { useAppDispatch, useAppSelector } from 'app/store'

import { QaImageList } from 'widgets/technicalDescriptions/Descriptions'

import { CopyToClipboardButton } from 'features/common/CopyToClipboardButton'
import {
  currentGalleryElementId,
  isQaImageGalleryOpen,
  qaImageGallerySlice,
} from 'features/logistics/openQAImageGallery'

import { useMobileBreakpoint } from 'shared/lib/useMobileBreakpoint'
import { flexDirectionOnBreakpoints, themeSpacingFromPixels } from 'shared/lib/workWithThemeSpacing'
import {AccordionExpandIconPlusTechCard} from 'shared/ui/Icons/AccordionExpandIconPlus/AccordionExpandIconPlusTechCard'
import AssemblyIconLogistics from 'shared/ui/Icons/AssemblyIcon'

import { UnitTechnicalDescription } from '../model/types'

import { ApproveStatus } from './ApproveStatus'
import { UnitDescriptionContent } from './UnitDescriptionContent'

interface TechnicalCardProps {
  unitDescription: UnitTechnicalDescription
  expanded: boolean
  assemblyUnit?: boolean
  topActionSlot?: ReactNode[]
  bottomActionSlot?: ReactNode[]
  unitAdditionalInfoSlot?: ReactNode
}

interface QaImagesDialogProps {
  unitDescription: UnitTechnicalDescription
}

export const QaImagesDialog: FC<QaImagesDialogProps> = ({
  unitDescription,
}) => {

  const dispatch = useAppDispatch()

  const isQaGalleryOpen = useAppSelector(isQaImageGalleryOpen)
  const currentQaGalleryId = useAppSelector(currentGalleryElementId)

  const onDialogClose = useCallback(() => {
    dispatch(qaImageGallerySlice.actions.setIsClosed())
  }, [dispatch])

  function openUppyEdit () {
    dispatch(qaImageGallerySlice.actions.setIsClosedOnApply())
    dispatch(qaImageGallerySlice.actions.setIsUploadOpen())
  }

  return currentQaGalleryId === unitDescription.elementId ?
    <Dialog
      open={isQaGalleryOpen}
      onClose={onDialogClose}
    >
      <DialogTitle>
        <Typography>Check QA images to edit</Typography>
      </DialogTitle>
      <DialogContent>
        <QaImageList unit={unitDescription}/>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onDialogClose}
        >Close</Button>
        <Button
          onClick={openUppyEdit}
        >Apply</Button>
      </DialogActions>
    </Dialog>
    : <></>
}

export const TechnicalCard: FC<TechnicalCardProps> = (
  {
    unitDescription,
    expanded,
    topActionSlot,
    bottomActionSlot,
    assemblyUnit,
    unitAdditionalInfoSlot,
  }) => {

  const {
    elementNameRus,
    content,
    elementId,
  }: UnitTechnicalDescription = unitDescription

  const mobileBreakpoint = useMobileBreakpoint()

  const [isExpanded, setIsExpanded] = useState(expanded)

  useEffect(() => {
    setIsExpanded(expanded)
  }, [expanded])

  const gapSize = 3
  return (

    <Stack
      data-testid="technical-card"
      gap={gapSize}
      sx={{
        ...flexDirectionOnBreakpoints,
        margin: themeSpacingFromPixels(0, 0, 24, 0),
        flex: 'auto',
      }}
    >
      <Stack
        gap={1}
        sx={{
          display: 'flex',
          flex: 'auto',
          maxWidth: '992px',
          height: 'fit-content',
        }}
      >
        <Accordion
          sx={{
            border: `${isExpanded ?
              logisticsTheme.palette.primary.main :
              '#D0D5DD'} 1px solid`,
            boxShadow: 'none',
          }}
          expanded={isExpanded}
          onChange={(e, expanded) => {
            setIsExpanded(expanded)
          }}
        >
          <AccordionSummary
            sx={{
              backgroundColor: isExpanded ?
                logisticsTheme.palette.primary.main :
                'white',
              color: isExpanded ?
                'white' :
                '#344054',

              borderRadius: themeSpacingFromPixels(7, 7, 0, 0),
              margin: themeSpacingFromPixels(0, 0, 8, 0),
              padding: themeSpacingFromPixels(6, 32, 2, 32),
              height: themeSpacingFromPixels(96),
            }}
          >
            <Stack
              direction={'row'}
              sx={{ width: '100%' }}
            >
              <AccordionExpandIconPlusTechCard isExpanded={isExpanded}/>
              <Grid
                container
                alignItems={'center'}
                alignContent={'center'}
              >
                <Grid
                  item
                  sm={12}
                  md={8}
                >
                  {
                    isExpanded
                      ?
                      topActionSlot
                      :
                      <Stack
                        direction="row"
                        alignItems={'center'}
                      >
                        <Typography variant="h1">{elementId}</Typography>
                        <CopyToClipboardButton
                          key={`copyid-${elementId || 0}`}
                          content={elementId?.toString() || ''}
                        />
                      </Stack>}
                </Grid>
                <Grid
                  item
                  sm={12}
                  md={2}
                  align-self={'flex-end'}
                >
                  {assemblyUnit && <AssemblyIconLogistics color={isExpanded ?
                    'white' :
                    'black'}
                  />}
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={2}
                >
                  {<ApproveStatus
                    unit={unitDescription}
                    isExpanded={isExpanded}
                  />}
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={12}
                >
                  <Typography variant="body1">{elementNameRus}</Typography>
                </Grid>
              </Grid>
            </Stack>
          </AccordionSummary>

          <AccordionDetails sx={{ padding: themeSpacingFromPixels(0, 0, 16, 0) }}>
            <Box sx={{
              padding: content?.length !== 0 ?
                mobileBreakpoint
                  ? themeSpacingFromPixels(0)
                  : themeSpacingFromPixels(24, 32, 0, 32)
                :
                0,
            }}
            >
              <UnitDescriptionContent content={content || []}/>
            </Box>

            <Stack
              direction="row"
              gap={1}
              justifyContent="space-between"
              flexWrap="wrap"
              sx={{ padding: themeSpacingFromPixels(24, 32, 8, 32) }}
            >
              {!!bottomActionSlot && bottomActionSlot}
            </Stack>
          </AccordionDetails>
        </Accordion>

      </Stack>
      <Box sx={{
        [baseTheme.breakpoints.up(baseTheme.breakpoints.values.md)]: { maxWidth: '360px' },
        [baseTheme.breakpoints.down(baseTheme.breakpoints.values.md)]: { minWidth: '404px' },
      }}
      >
        {isExpanded && unitAdditionalInfoSlot}
      </Box>

      <QaImagesDialog unitDescription={unitDescription}></QaImagesDialog>

    </Stack>
  )
}

TechnicalCard.defaultProps = {
  assemblyUnit: false,
  topActionSlot: undefined,
  bottomActionSlot: undefined,
}
