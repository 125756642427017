import {
  Box,
  Button,
  Tooltip,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { type FC } from 'preact/compat'
import {
  useEffect,
  useState,
} from 'react'
import wretch from 'wretch'

import {
  useAppDispatch,
  useAppSelector,
} from 'app/store/hooks'
import { type RootState } from 'app/store/store'
import {
  loadInitials,
  open,
} from 'app/store/units/initialsDialogSlice'
import { checkPermission } from 'app/store/user/userSlice'

import { type UnitTechnicalDescription } from 'entities/logistics/TechnicalCard'

import authService from 'shared/api/AuthService'
import { useFeatureSlicedDebug } from 'shared/lib/useFeatureSlicedDebug'
import { useMobileBreakpoint } from 'shared/lib/useMobileBreakpoint'
import { themeSpacingFromPixels } from 'shared/lib/workWithThemeSpacing'
import { PermissionsDocumentationInitialsView } from 'shared/model/contracts/permissions'
import { SearchUnitDto } from 'shared/model/contracts/UnitDto'

import { API_GATEWAY_ADDRESS } from '../../../../shared/api/api'

interface ShowInitialsProps {
  unit: SearchUnitDto | UnitTechnicalDescription
}

export const ShowInitials: FC<ShowInitialsProps> = ({
  unit,
}) => {

  const {
    enqueueSnackbar,
  } = useSnackbar()
  const dispatch = useAppDispatch()

  const mobileBreakpoint = useMobileBreakpoint()

  const [isInitialsAvailable, setIsInitialsAvailable] = useState(false)
  const [unitId, setUnitId] = useState(0)

  useEffect(() => {
    let unitId = 0
    if ('unitFileId' in unit) {
      unitId = unit?.unitFileId || 0
    }

    if ('unitId' in unit) {
      unitId = unit?.unitId || 0
    }
    setUnitId(unitId)
  }, [unit])

  useEffect(() => {
    const getAvData = async () => {
      if (!unitId) {
        return
      }
      const fullApiUrl = `${API_GATEWAY_ADDRESS}/ec/units/${unitId}/files/initials`
      const token = await authService.getIdentity()

      const apiWretch = wretch(fullApiUrl)
        .auth(`Bearer ${token.accessToken}`)

      try {
        const res = await apiWretch
          .get()
          .json()

        if (res) {
          const j = JSON.stringify(res)
          const t: { files: unknown, error: string } = JSON.parse(j)
          if (t.files) {
            setIsInitialsAvailable(true)
          }

        }
      } catch (err) {
        enqueueSnackbar(`Failed to get images for ${unitId} `)
      }
    }

    getAvData()

  }, [unitId, enqueueSnackbar])

  const canViewInitials = useAppSelector((root: RootState) =>
    checkPermission(root, PermissionsDocumentationInitialsView),
  )

  const {
    rootAttributes,
  } = useFeatureSlicedDebug('feature/ShowInitials')

  const handleInitialsClick = async () => {

    let oemArticle = ''
    if ('oemArticle' in unit) {
      oemArticle = unit?.oemArticle ?? ''
    }

    if ('oemArcticleName' in unit) {
      oemArticle = unit?.oemArcticleName || ''
    }

    dispatch(loadInitials({ unitId }))
      .then(() =>
        dispatch(open({
          unitFileId: unitId,
          elementId: unit.elementId,
          oemArticle: oemArticle,
        })))

  }

  if (!canViewInitials) {
    return <></>
  }

  if (!isInitialsAvailable) {
    return <></>
  }

  return (
    <Box {...rootAttributes}>
      <Tooltip title={'Download Initials'}>
        <Button
          sx={{
            width: mobileBreakpoint
              ? '100%'
              : themeSpacingFromPixels(100),
            height: themeSpacingFromPixels(40),
          }}
          onClick={handleInitialsClick}
          variant={'outlined'}
        >
          Initials
        </Button>
      </Tooltip>
    </Box>
  )
}

ShowInitials.displayName = 'ShowInitials'
