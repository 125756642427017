import { baseTheme } from 'app/config/theme/baseTheme'
import { logisticsTheme } from 'app/config/theme/logisticsTheme'

export function themeSpacingFromPixels (...spacingInputInPx: number[]): string {
  const currentSpacing = Number(logisticsTheme.spacing().replace('px', ''))
  const convertedSpacing = spacingInputInPx?.map(sp => Number((sp / currentSpacing).toFixed(2)))
  if (convertedSpacing.length === 1) {
    return baseTheme.spacing(convertedSpacing[0])
  }

  if (convertedSpacing.length === 2) {
    return baseTheme.spacing(
      convertedSpacing[0],
      convertedSpacing[1],
    )
  }
  if (convertedSpacing.length === 3) {
    return baseTheme.spacing(
      convertedSpacing[0],
      convertedSpacing[1],
      convertedSpacing[2],
    )
  }

  if (convertedSpacing.length === 4) {
    return baseTheme.spacing(
      convertedSpacing[0],
      convertedSpacing[1],
      convertedSpacing[2],
      convertedSpacing[3],
    )
  }

  return ''
}

export const flexDirectionOnBreakpoints = {
  [baseTheme.breakpoints.down(baseTheme.breakpoints.values.md)]: { flexDirection: 'column' },
  [baseTheme.breakpoints.up(baseTheme.breakpoints.values.md)]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}

export const flexDirectionOnBreakpointsColumn = {
  [baseTheme.breakpoints.down(baseTheme.breakpoints.values.md)]: { flexDirection: 'column' },
  [baseTheme.breakpoints.up(baseTheme.breakpoints.values.md)]: {flexDirection: 'row'},
}
