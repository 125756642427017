import {
  DialogTitle, Divider, Stack, Dialog, DialogActions, DialogContent, Collapse,
  Button, TextField,
} from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs, { Dayjs } from 'dayjs'
import { FC, SyntheticEvent } from 'react'
import * as React from 'react'

import { useAppDispatch } from '../../app/store'
import { scheduleSyncUnitData } from '../../app/store/administration/syncSlice'
import { themeSpacingFromPixels } from '../../shared/lib/workWithThemeSpacing'

interface Props {
  open: boolean
  setOpen: (open: boolean) => void
}

export const ScheduleDialog: FC<Props> = ({
  open,
  setOpen,
}) => {
  const handleDialogClose = (_: SyntheticEvent, reason: string) => {
    if (reason && (reason === 'backdropClick' || reason === 'escapeKeyDown')) {
      setOpen(false)
    }
  }

  const dispatch = useAppDispatch()
  const [schedule, setSchedule] = React.useState<Dayjs | null>(null)

  const handleOnChange = (value: dayjs.Dayjs | null) => {
    setSchedule(value)
  }

  const handleDialogSelect = () => {
    if (schedule) {
      dispatch(scheduleSyncUnitData(schedule.format()))
    }
    setOpen(false)
  }

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { maxWidth: themeSpacingFromPixels(450) } }}
      open={open}
      onClose={handleDialogClose}
    >
      <DialogTitle>{`Schedule sync action`}</DialogTitle>
      <Divider/>
      <DialogContent>
        <Collapse in={true}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={'ru'}
          >
            <DateTimePicker
              label="Schedule at"
              value={schedule}
              ampm={false}
              onChange={handleOnChange}
              inputFormat="DD-MM-YYYY HH:mm"
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </Collapse>
      </DialogContent>
      <Divider/>
      <DialogActions>
        <Stack
          direction={'row'}
          spacing={1}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={handleDialogSelect}
          >
            Confirm
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setOpen(false)
            }}
          >
            Cancel
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  )
}
