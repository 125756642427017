import { combineReducers } from '@reduxjs/toolkit'

import brokersSlice from './brokerSlice'
import editOptions from './editRoleOptionsSlice'
import editRights from './editRoleRightsSlice'
import editSettings from './editSettingsSlice'
import editRole from './roleSlice'
import rolesList from './rolesListSlice'
import selectUsers from './selectRoleUsersSlice'
import settings from './settingsSlice'
import syncSlice from './syncSlice'
import usersList from './usersListSlice'

export const administration = combineReducers({
  users: combineReducers({ list: usersList }),
  roles: combineReducers({
    list: rolesList,
    edit: combineReducers({
      editRole,
      selectUsers,
      editRights,
      editOptions,
    }),
  }),
  settings: combineReducers({
    settings,
    editSettings,
  }),
  sync: syncSlice,
  brokers: brokersSlice,
})
