import {
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react'
import { type AnyAction } from 'redux'
import { type ThunkDispatch } from 'redux-thunk'
import wretch from 'wretch'
import { z } from 'zod'

import {
  type RootState,
  store,
} from 'app/store'

import authService from 'shared/api/AuthService'
import { setAuthTokenToHeaders } from 'shared/api/wretch/authorization'
import { schemas } from 'shared/model/commonLogisticApiSchemes'
import {
  errorBody,
  httpAPIResponseScheme,
  HttpAPIResponseTyped,
} from 'shared/model/contracts/transportLevelTypes'

import { API_GATEWAY_ADDRESS } from '../../../../shared/api/api'

import {
  type ManufacturingMethod,
  type Material,
  type Principle,
  TechnicalDescriptionImage,
  type UnitTechnicalDescription,
  TechnicalDescriptionScheme, unitForecastValuesSchema, Purpose,
} from './types'

const unitTechnicalDescriptionSchemaAnswer = z.array(schemas.LogisticUnitDto).optional().nullable()
const unitForecastValuesSchemaAnswer = z.array(unitForecastValuesSchema).optional().nullable()

interface UnitIdObj {
  unitId: UnitTechnicalDescription[ 'unitId']
}

export type RequestUnitImage = UnitIdObj & { imageId: TechnicalDescriptionImage['id'] }
export type UpdateEquipmentTypeData = UnitIdObj & { equipmentTypeId: number }
export type UpdateManufacturingMethodsData = UnitIdObj & { manufacturingMethodsIds: number[] }
export type UpdateImageData = UnitIdObj & { images: TechnicalDescriptionImage[] }
export type UpdateSchemesData = UnitIdObj & { schemes: TechnicalDescriptionScheme[] }
export type UpdateMaterialsData = UnitIdObj & { materialIds: number[] }
export type UpdatePurposesData = UnitIdObj & { purpose: string }
export type UpdatePositionData = UnitIdObj & { position: string }
export type UpdatePrinciplesData = UnitIdObj & { principleIds: number[] }
export type UpdateUnitStateData = UnitIdObj & { state: UnitTechnicalDescription['state'] }
export type UnitForecastValuesData = UnitIdObj & {
  materials: UnitTechnicalDescription['materials'],
  manufacturingMethods: UnitTechnicalDescription['manufacturingMethods']
  purposes: Purpose[]
}

export function isUpdateImageData (data: any): data is UpdateImageData {
  return 'unitId' in data && 'images' in data
}

export function isUpdateSchemesData (data: any): data is UpdateSchemesData {
  return 'unitId' in data && 'schemes' in data
}

export function technicalCardCacheUpdate<QueryArg> (
  dispatch: ThunkDispatch<any, any, AnyAction>,
  unitId: UnitTechnicalDescription['unitId'],
  patch: Omit<QueryArg, 'unitId'>,
) {
  const updatedQueryName = 'getUnitDescriptions'
  const invalidationData = technicalCardsApi.util.selectInvalidatedBy(store.getState(), [
    {
      type: 'Unit',
      id: 'LIST',
    },
  ])
  const originalArgs = invalidationData?.find(q => q?.endpointName === updatedQueryName && q.originalArgs.includes(unitId))?.originalArgs
  const originalArgsArr = invalidationData?.filter(q => q?.endpointName === updatedQueryName && q.originalArgs.includes(unitId))
  originalArgsArr.forEach(arg => {
    dispatch(
      technicalCardsApi.util.updateQueryData(updatedQueryName, arg.originalArgs, (draft) => {
        const index = draft.findIndex(i => i.unitId === unitId)
        Object.assign(draft[index], patch)
      }),
    )
  })
  return dispatch(
    technicalCardsApi.util.updateQueryData(updatedQueryName, originalArgs, (draft) => {
      const index = draft.findIndex(i => i.unitId === unitId)
      Object.assign(draft[index], patch)
    }),
  )
}

function getTransformedEquipmentTypesPatch<QueryArg> (patch: Omit<QueryArg, 'unitId'>) {
  const currentState: RootState = store?.getState()
  // @ts-ignore
  const data = (mId: UnitTechnicalDescription['unitId']) => currentState?.equipmentTypesApi?.queries['getEquipmentTypes(undefined)']?.data?.find((t: EquipmentType) => t?.id === mId)
  // @ts-ignore
  return { equipmentType: data(patch.equipmentTypeId) }
}

function getTransformedPrinciplePatch<QueryArg> (patch: Omit<QueryArg, 'unitId'>) {
  const currentState: RootState = store?.getState()
  // @ts-ignore
  const data = (mId: UnitTechnicalDescription['unitId']) => currentState?.principlesApi?.queries['getPrinciples(undefined)']?.data?.find((t: Principle) => t?.id === mId)
  // @ts-ignore
  return { principles: patch.principleIds.map(data) }
}

function getTransformedPurposesPatch ({
  purpose,
}: { purpose: string }) {
  return { purpose: purpose }
}

function getTransformedManufacturingMethodsPatch<QueryArg> (patch: Omit<QueryArg, 'unitId'>) {
  const currentState: RootState = store?.getState()
  // @ts-ignore
  const data = (mId: UnitTechnicalDescription['unitId']) => currentState?.manufacturingMethodsApi?.queries['getManufacturingMethods(undefined)']?.data?.find((t: ManufacturingMethod) => t?.id === mId)
  // @ts-ignore
  return { manufacturingMethods: patch.manufacturingMethodsIds.map(data) }
}

function getTransformedMaterialsPatch<QueryArg> (patch: Omit<QueryArg, 'unitId'>) {
  const currentState: RootState = store?.getState()
  // @ts-ignore
  const data = (mId: UnitTechnicalDescription['unitId']) => currentState?.materialsApi?.queries['getMaterials(undefined)']?.data?.find((t: Material) => t?.id === mId)
  // @ts-ignore
  return { materials: patch.materialIds.map(data) }
}

const fullApiUrl = `${API_GATEWAY_ADDRESS}/el/units`

export const technicalCardsApi = createApi({
  reducerPath: 'technicalCardsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: fullApiUrl,
    prepareHeaders: async (headers: Headers) => await setAuthTokenToHeaders(headers),
  }),
  tagTypes: ['Unit'],
  endpoints: (builder) => ({
    getUnitDescriptions: builder.query<UnitTechnicalDescription[], Array<UnitTechnicalDescription['unitId']>>({
      queryFn: async (arg) => {
        const token = await authService.getIdentity()
        const apiWretch = wretch(fullApiUrl)
          .auth(`Bearer ${token.accessToken}`)

        const res = await apiWretch.post({ unitIds: [...arg] })
          .json()
        unitTechnicalDescriptionSchemaAnswer.parse(res)
        return { data: res as UnitTechnicalDescription[] }
      },
      providesTags: (result) =>
        result
          ? [
            ...result.map(({
              unitId,
            }) => ({
              type: 'Unit',
              id: unitId,
            } as const)),
            {
              type: 'Unit',
              id: 'LIST',
            },
          ]
          : [
            {
              type: 'Unit',
              id: 'LIST',
            },
          ],
    }),
    updateEquipmentType: builder.mutation<{
      equipmentTypeId: number
    }, UpdateEquipmentTypeData>({
      query: ({
        unitId,
        ...patch
      }) => ({
        url: `${unitId}/equipment-type`,
        method: 'PUT',
        body: { ...patch },
      }),
      transformResponse: (response: {
        equipmentTypeId: number
      }, meta, arg) => response,
      transformErrorResponse: (
        response: { status: string | number },
        meta,
        arg,
      ) => response.status,
      onQueryStarted (
        {
          unitId,
          ...patch
        },
        {
          dispatch,
          queryFulfilled,
        },
      ) {
        const transformedPatch = getTransformedEquipmentTypesPatch(patch)
        const patchResult = technicalCardCacheUpdate(dispatch, unitId, transformedPatch)
        queryFulfilled.catch(() => {
          patchResult.undo()
          dispatch(technicalCardsApi.util.invalidateTags([
            {
              type: 'Unit',
              id: unitId,
            },
          ]))
        })
      },
    }),
    updatePositionUnit: builder.mutation<{
      position: UnitTechnicalDescription['position']
    }, UpdatePositionData>({
      query: ({
        unitId,
        ...patch
      }) => ({
        url: `${unitId}/position`,
        method: 'PUT',
        body: { ...patch },
      }),
      onQueryStarted (
        {
          unitId,
          ...patch
        },
        {
          dispatch,
          queryFulfilled,
        },
      ) {
        const patchResult = technicalCardCacheUpdate(dispatch, unitId, patch)
        queryFulfilled.catch(() => {
          patchResult.undo()
          dispatch(technicalCardsApi.util.invalidateTags([
            {
              type: 'Unit',
              id: unitId,
            },
          ]))
        })
      },
    }),
    updateManufacturingMethods: builder.mutation <{
      manufacturingMethodsIds: number[]
    }, UpdateManufacturingMethodsData>({
      query: ({
        unitId,
        ...patch
      }) => ({
        url: `${unitId}/manufacturing-methods`,
        method: 'PUT',
        body: { ...patch },
      }),
      transformResponse: (response: {
        manufacturingMethodsIds: number[]
      }, meta, arg) => response,
      transformErrorResponse: (
        response: {
          status: string | number
        },
        meta,
        arg,
      ) => response.status,
      onQueryStarted (
        {
          unitId,
          ...patch
        },
        {
          dispatch,
          queryFulfilled,
        },
      ) {

        const transformedPatch = getTransformedManufacturingMethodsPatch(patch)
        const patchResult = technicalCardCacheUpdate(dispatch, unitId, transformedPatch)
        queryFulfilled.catch(() => {
          patchResult.undo()
          dispatch(technicalCardsApi.util.invalidateTags([
            {
              type: 'Unit',
              id: unitId,
            },
          ]))
        })
      },
    }),
    updateMaterialsUnit: builder.mutation<{
      materialIds: number[]
    }, UpdateMaterialsData>({
      query: ({
        unitId,
        ...patch
      }) => ({
        url: `${unitId}/materials`,
        method: 'PUT',
        body: { ...patch },
      }),
      transformResponse: (response: {
        materialIds: number[]
      }, meta, arg) => response,
      onQueryStarted (
        {
          unitId,
          ...patch
        },
        {
          dispatch,
          queryFulfilled,
        },
      ) {
        const transformedPatch = getTransformedMaterialsPatch(patch)
        const patchResult = technicalCardCacheUpdate(dispatch, unitId, transformedPatch)

        queryFulfilled.catch(() => {
          patchResult.undo()
          dispatch(technicalCardsApi.util.invalidateTags([
            {
              type: 'Unit',
              id: unitId,
            },
          ]))
        })
      },
    }),
    updatePurposesUnit: builder.mutation<{
      purpose: string
    }, UpdatePurposesData>({
      query: ({
        unitId,
        ...patch
      }) => ({
        url: `${unitId}/purposes`,
        method: 'PUT',
        body: { ...patch },
      }),
      transformResponse: (response: {
        purpose: string
      }, meta, arg) => response,
      onQueryStarted (
        {
          unitId,
          ...patch
        },
        {
          dispatch,
          queryFulfilled,
        },
      ) {
        const transformedPatch = getTransformedPurposesPatch(patch)
        const patchResult = technicalCardCacheUpdate(dispatch, unitId, transformedPatch)

        queryFulfilled.catch(() => {
          patchResult.undo()
          dispatch(technicalCardsApi.util.invalidateTags([
            {
              type: 'Unit',
              id: unitId,
            },
          ]))
        })
      },
    }),
    updateApproveCardState: builder.mutation<UpdateUnitStateData, UpdateUnitStateData>({
      query: ({
        unitId,
        ...patch
      }) => ({
        url: `${unitId}/state`,
        method: 'PUT',
        body: patch,
      }),
      transformResponse: (
        response: HttpAPIResponseTyped<UpdateUnitStateData>,
        meta,
        arg) => {
        httpAPIResponseScheme.parse(response)
        if (!response.success) {
          const error = response.body as errorBody
          throw Error(`Error ${error.code}: ${error.message}  `)
        }
        return response.body as UpdateUnitStateData
      },
      transformErrorResponse: (
        response: {
          status: string | number
        },
        meta,
        arg,
      ) => response.status,
      invalidatesTags: (
        result,
        error,
        {
          unitId,
        }) => [
        {
          type: 'Unit',
          id: unitId,
        },
      ],
    }),
    getUnitForecastValues: builder.mutation<UnitForecastValuesData, UnitIdObj>({
      query: ({
        unitId,
      }) => ({
        url: `${unitId}/forecast`,
        method: 'get',
      }),
    }),
  }),
})

export const {
  useGetUnitDescriptionsQuery,
  useUpdateEquipmentTypeMutation,
  useUpdateManufacturingMethodsMutation,
  useUpdateMaterialsUnitMutation,
  useUpdatePurposesUnitMutation,
  useGetUnitForecastValuesMutation,
} = technicalCardsApi
