export const msalFetch = () => {
  const authHeader = (accessToken: string, init?: HeadersInit) => {
    const headers = new Headers(init)

    const bearer = `Bearer ${accessToken}`
    headers.append('Authorization', bearer)

    return headers
  }

  const request = (method: string) => {
    return async function (
      url: string,
      accessToken: string,
      body?: any,
      headers?: HeadersInit,
      mode?: string,
    ) {
      const requestOptions = {
        method,
        headers: authHeader(accessToken, headers),
      } as RequestInit
      if (body) {
        const newHeaders = new Headers(requestOptions.headers)
        newHeaders.set('Content-Type', 'application/json')

        requestOptions.headers = newHeaders
        requestOptions.body = JSON.stringify(body)
      }
      return await fetch(url, requestOptions)
    }
  }

  return {
    get: request('GET'),
    post: request('POST'),
    put: request('PUT'),
    delete: request('DELETE'),
  }
}
