import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Skeleton, Checkbox, Button, Stack, AccordionSummary, Accordion, AccordionDetails,
  ListItemButton,
} from '@mui/material'
import Typography from '@mui/material/Typography'
import _ from 'lodash'
import { type FunctionComponent, useState } from 'react'

import { useAppDispatch, useAppSelector } from 'app/store'

import {selectSync, checkCheck, checkChecks, unCheckChecks, loadCheckData, loadCheckMessage} from '../../../app/store/administration/syncSlice'
import { TextDialog } from '../../sync/TextDialog/ui/TextDialog'

const SyncList: FunctionComponent = () => {

  const dispatch = useAppDispatch()
  const syncState = useAppSelector(selectSync)
  const {
    status,
    checks,
  } = syncState

  const items = _.orderBy(checks, ['type'])
  const groups = _.groupBy(items, 'type')
  const [text, setText] = useState<string | undefined>(undefined)

  if (status === 'loading') {
    return (
      <List>
        {Array.from(Array(5).keys()).map((i) => {
          return (
            <Box
              key={i}
              sx={{ width: '100%' }}
            >
              <Skeleton/>
            </Box>
          )
        })}
      </List>
    )
  }

  return (
    <>
      <List>
        {syncState.processing &&
          <ListSubheader
            key={'processing'}
            color="primary"
            disableSticky
          >
            Sync jobs in processing
          </ListSubheader>
        }
        {syncState.checks && syncState.checks.length === 0 && !syncState.processing &&
          <ListSubheader
            key={'no-checks'}
            color="primary"
            disableSticky
          >
            No check entities
          </ListSubheader>
        }
        {syncState.checks && !syncState.processing &&
          <>
            {_.map(groups, (group, name) => {

              const labelHeaderId = `checkbox-list-primary-label-${name}`
              return (
                <Box
                  key={name}
                  m={1}
                >
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon/>}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Stack
                        spacing={2}
                        direction={'row'}
                        alignItems={'center'}
                        justifyContent={'center'}
                      >
                        <Checkbox
                          checked={group.every(ch => ch.checked)}

                          onClick={(e) => {
                            e.stopPropagation()
                            if (group.every(ch => ch.checked)) {
                              dispatch(unCheckChecks(name))
                            } else {
                              dispatch(checkChecks(name))
                            }
                          }}
                          edge="end"
                          inputProps={{ 'aria-labelledby': labelHeaderId }}
                        />
                        <Typography>
                          {name}
                        </Typography>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails>
                      {group.map((c) => {
                        return (
                          <ListItem
                            key={`check-list-item-${c.id}`}
                            sx={{
                              flexWrap: 'wrap',
                              alignContent: 'center',
                            }}
                          >
                            <ListItemButton
                              role={undefined}
                              onClick={() => {
                                dispatch(checkCheck(c.id))
                              }}
                              dense
                            >
                              <ListItemText
                                primary={c.entityId}
                                secondary={c.subMessage}
                                sx={{ wordBreak: 'break-аll' }}
                              />
                              <Stack
                                direction="row"
                                spacing={2}
                              >
                                <Button onClick={async (e) => {
                                  e.stopPropagation()
                                  const text = await dispatch(loadCheckData(c.id)).unwrap()
                                  setText(text)
                                }}
                                >Data</Button>
                                <Button onClick={async (e) => {
                                  e.stopPropagation()
                                  const text = await dispatch(loadCheckMessage(c.id)).unwrap()
                                  setText(text)
                                }}
                                >Message</Button>
                              </Stack>
                            </ListItemButton>
                            <Checkbox
                              checked={c.checked}
                              edge="end"
                              inputProps={{ 'aria-labelledby': `checkbox-list-secondary-label-${c.id}` }}
                            />
                          </ListItem>
                        )
                      })}
                    </AccordionDetails>
                  </Accordion>

                </Box>
              )
            },
            )}
          </>
        }
      </List>
      <TextDialog
        text={text}
        setText={setText}
      />
    </>
  )
}

export default SyncList
